<template>
  <b-container class="the-detail">
    <b-form @submit.stop.prevent="create">
      <b-row>
        <h1 class="pageheading">{{ $t('owner.titles.create') }}</h1>
      </b-row>
      <b-row class="the-detail__toolbar">
        <CRUDToolbar
        :clickFn="create"
        :hasBackButton="false">
        </CRUDToolbar>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.name') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              v-validate="{ required: true }"
              :class="{'validation--error': veeErrors.has('name') }"
              name="name"
              id="name"
              v-model="formInputs.name">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.street') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
            id="street"
            v-model="formInputs.street">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.zip') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="zip"
              v-model="formInputs.zip">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.location') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="location"
              v-model="formInputs.location">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.country') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <Countries
              v-on:country="setCountry"
              :showLabel="false">
            </Countries>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.phone') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              name="phone"
              id="phone"
              v-model="formInputs.phone">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.fax') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="fax"
              v-model="formInputs.fax">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.email') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              v-validate="{ required: true }"
              :class="{'validation--error': veeErrors.has('email') }"
              name="email"
              id="email"
              v-model="formInputs.email">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.website') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="website"
              v-model="formInputs.website">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.numberOfProductionSites') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              v-validate="{ required: true }"
              :class="{'validation--error': veeErrors.has('numberOfProductionSites') }"
              type="number"
              name="numberOfProductionSites"
              id="numberOfProductionSites"
              v-model="formInputs.numberOfProductionSites">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.moreContacts') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
            id="moreContacts"
            v-model="formInputs.moreContacts">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.logo') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <FileInput v-on:setFile="setFile"/>
          </b-col>
        </b-row>
      </b-row>
      <CRUDToolbar :clickFn="create"></CRUDToolbar>
    </b-form>
  </b-container>
</template>

<script>
import CRUDToolbar from '../components/CRUDToolbar'
import Countries from '../components/Selects/Countries'
import { toaster } from '../services/toast'
import { mapActions } from 'vuex'
import jsonToFormData from 'json-form-data'
import FileInput from '../components/FileInput'
import { _underscore } from '@suben/keys_transformer'

export default {
  name: 'TheOwnerCreate',
  components: { CRUDToolbar, Countries, FileInput },
  data () {
    return {
      formInputs: {
        name: '',
        street: '',
        zip: '',
        location: '',
        country_id: '',
        email: '',
        website: '',
        phone: '',
        fax: '',
        moreContacts: '',
        numberOfProductionSites: null
      }
    }
  },
  methods: {
    ...mapActions('certificates/owners', ['fetch']),
    /**
     *
     * @param value
     * @param logoType
     *
     * @return {void}
     */
    setFile ({ value, logoType }) {
      if (logoType === 'logo' && ('logo_url' in this.formInputs)) {
        Reflect.deleteProperty(this.formInputs, 'logo_url')
      }

      if (logoType === 'logo_url' && ('logo' in this.formInputs)) {
        Reflect.deleteProperty(this.formInputs, 'logo')
      }

      this.formInputs[logoType] = value
    },
    /**
     *
     * @return {void}
     */
    async create () {
      const initToaster = toaster(this['_bv__toast'])
      const isValid = await this.$validator.validateAll()

      if (isValid === false) return

      try {
        await this.$api.create('owners', jsonToFormData(
          Object.deleteEmpty(_underscore(this.formInputs, ['__ob__']))
        ))

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))

        await this.fetch({ limit: 500 })

        this.$router.push('/owners/1/list')
      } catch ({ data }) {
        const toaster = initToaster(data.message)

        toaster(this.$t('notifications.error.title'), this.$t(`notifications.error.${data.status_code}`))
      }
    },
    /**
     * @param value
     *
     * @return {void}
     */
    setCountry (value) {
      this.formInputs.country_id = value
    }
  }
}
</script>
