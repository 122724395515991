import { SET_SIGNS } from './mutationTypes'
import Api from '../services/api'

const state = {
  signs: []
}
const mutations = {
  /**
   *
   * @param state
   * @param signs
   */
  [SET_SIGNS] (state, signs) {
    state.signs = signs
  }
}
const getters = {
  /**
   *
   * @param state
   * @return {getters.signs|(function(*))|[]|*}
   */
  signs (state) {
    return state.signs
  }
}
const actions = {
  async fetch ({ commit } = {}, options = {}) {
    const api = new Api()
    const { limit } = options

    commit('setSigns', await api.get('conformitySigns', { params: { limit } }))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
