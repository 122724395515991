<template>
  <multiselect
    v-if="certificationBodiesLoaded"
    :placeholder="$t('common.selects.certificationBody')"
    track-by="name"
    label="name"
    id="certification_body_id"
    :options="certificationBodies.data"
    v-model="selectedCertificationBody">
  </multiselect>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'CertificationBodies',
  props: {
    preselect: {
      validator (value) {
        return typeof value === 'number' || typeof value === 'string'
      }
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      selectedCertificationBody: null
    }
  },
  mounted () {
    if ('data' in this.certificationBodies && this.certificationBodies.data.length > 0) return

    this.fetch({ limit: 500 }).then()
  },
  components: { Multiselect },
  computed: {
    ...mapGetters('common/certificationBodies', ['certificationBodies']),
    /**
     * @return {Boolean}
     */
    certificationBodiesLoaded () {
      return this.certificationBodies.hasOwnProperty('data')
    }
  },
  watch: {
    /**
     * @param value
     */
    selectedCertificationBody (value) {
      if (value === null || typeof value === 'undefined') {
        this.$emit('certificationBody', null)

        return
      }

      this.$emit('certificationBody', value.id)
    },
    /**
     * @param value
     */
    preselect (value) {
      if (this.certificationBodies.hasOwnProperty('data') === false) return

      const certificationBody = this.certificationBodies.data.filter(({ id }) => id === value) || null

      this.selectedCertificationBody = certificationBody ? certificationBody[0] : null
    },
    /**
     * @param value
     */
    certificationBodies (value) {
      if (!this.preselect) return

      const certificationBody = value.data.filter(({ id }) => id === this.preselect)

      this.selectedCertificationBody = certificationBody ? certificationBody[0] : null
    }
  },
  methods: {
    ...mapActions('common/certificationBodies', ['fetch'])
  }
}
</script>
