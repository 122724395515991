const reset = {
  title: 'Password reset',
  description: 'Forgot your passwort? No worries.',
  link: 'Reset here',
  form: {
    email: 'Email',
    button: 'Reset'
  }
}

export default reset
