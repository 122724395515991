import Vue from 'vue'
import Router from 'vue-router'
import env from '../env'

// Certificates import view
import TheCertificatesImport from './views/TheCertificatesImport'

// Home view, where a user can start the search
import TheSearch from './views/TheSearch.vue'

// Login view
import TheLogin from './views/TheLogin.vue'

// Certificate views
import TheCertificatesDetail from './views/TheCertificatesDetail.vue'
import TheCertificates from './views/TheCertificates.vue'
import TheCertificatesCreate from './views/TheCertificatesCreate'
import TheCertificatesUpdate from './views/TheCertificatesUpdate'
import TheCertificatesList from './views/TheCertificatesList'

// Certification body views
import TheCertificationBodies from './views/TheCertificationBodies'
import TheCertificationBodiesList from './views/TheCertificationBodiesList'
import TheCertificationBodiesUpdate from './views/TheCertificationBodiesUpdate'
import TheCertificationBodiesCreate from './views/TheCertificationBodiesCreate'
import TheCertificationBodiesDetail from './views/TheCertificationBodiesDetail'

// Owner views
import TheOwners from './views/TheOwners'
import TheOwnersList from './views/TheOwnersList'
import TheOwnersUpdate from './views/TheOwnersUpdate'
import TheOwnersCreate from './views/TheOwnersCreate'

// Laboratory views
import TheLaboratories from './views/TheLaboratories'
import TheLaboratoriesList from './views/TheLaboratoriesList'
import TheLaboratoriesCreate from './views/TheLaboratoriesCreate'
import TheLaboratoriesUpdate from './views/TheLaboratoriesUpdate'

// The users profile view
import TheUser from './views/TheUser'
import TheUserProfile from './views/TheUserProfile'
import TheUserProfileCreate from './views/TheUserProfileCreate'

// The standards view
import TheStandards from './views/TheStandards'
import TheStandardsList from './views/TheStandardsList'
import TheStandardsCreate from './views/TheStandardsCreate'
import TheStandardsUpdate from './views/TheStandardsUpdate'

// The product types view
import TheProductTypes from './views/TheProductTypes'
import TheProductTypesList from './views/TheProductTypesList'
import TheProductTypesCreate from './views/TheProductTypesCreate'
import TheProductTypesUpdate from './views/TheProductTypesUpdate'
import TheUserList from './views/TheUserList'
import ThePasswordReset from './views/ThePasswordReset'
import TheDownloads from './views/TheDownloads'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'start',
    component: TheSearch
  },
  {
    path: '/login',
    name: 'login',
    component: TheLogin
  },
  {
    path: '/reset',
    name: 'reset',
    component: ThePasswordReset
  },
  {
    path: '/downloads',
    name: 'downloads',
    component: TheDownloads
  },
  {
    path: '/certificates',
    component: TheCertificates,
    children: [
      {
        path: '',
        name: 'certificates',
        component: TheCertificatesCreate
      },
      {
        path: ':id/list',
        component: TheCertificatesList
      },
      {
        path: ':id/update',
        component: TheCertificatesUpdate
      },
      {
        path: ':id/detail',
        component: TheCertificatesDetail
      }
    ]
  },
  {
    path: '/certificationBodies',
    component: TheCertificationBodies,
    children: [
      {
        path: '',
        name: 'certificationBodies',
        component: TheCertificationBodiesCreate
      },
      {
        path: ':id/list',
        component: TheCertificationBodiesList
      },
      {
        path: ':id/update',
        component: TheCertificationBodiesUpdate
      },
      {
        path: ':id/detail',
        component: TheCertificationBodiesDetail
      }
    ]
  },
  {
    path: '/owners',
    component: TheOwners,
    children: [
      {
        path: '',
        name: 'owners',
        component: TheOwnersCreate
      },
      {
        path: ':id/list',
        component: TheOwnersList
      },
      {
        path: ':id/update',
        component: TheOwnersUpdate
      }
    ]
  },
  {
    path: '/productTypes',
    component: TheProductTypes,
    children: [
      {
        name: 'productTypes',
        path: '',
        component: TheProductTypesCreate
      },
      {
        path: ':id/list',
        component: TheProductTypesList
      },
      {
        path: ':id/update',
        component: TheProductTypesUpdate
      }
    ]
  },
  {
    path: '/standards',
    component: TheStandards,
    children: [
      {
        name: 'standards',
        path: '',
        component: TheStandardsCreate
      },
      {
        path: ':id/list',
        component: TheStandardsList
      },
      {
        path: ':id/update',
        component: TheStandardsUpdate
      }
    ]
  },
  {
    path: '/laboratories',
    component: TheLaboratories,
    children: [
      {
        path: '',
        name: 'laboratories',
        component: TheLaboratoriesCreate
      },
      {
        path: ':id/list',
        component: TheLaboratoriesList
      },
      {
        path: ':id/update',
        component: TheLaboratoriesUpdate
      }
    ]
  },
  {
    path: '/users/:id/list',
    component: TheUserList
  },
  {
    path: '/users/:id',
    component: TheUserProfile
  },
  {
    path: '/me',
    component: TheUser,
    children: [
      {
        path: 'certificates/:id',
        component: TheCertificatesList
      },
      {
        path: 'import',
        component: TheCertificatesImport
      }
    ]
  },
  {
    path: '/profile/new',
    component: TheUserProfileCreate
  }
]
const router = new Router({ routes })

/**
 *
 * @param params
 * @param params.to
 * @param params.from
 * @param params.next
 */
const routeGuard = async (...params) => {
  const { path } = params[0]
  const isGuarded = path.includes('update') || path.includes('new') || path.includes('me')

  if (isGuarded && !sessionStorage.getItem(env.ID_TOKEN)) {
    params[2](false)
    return
  }

  params[2](true)
}

router.beforeEach(routeGuard)

export default router
