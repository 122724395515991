<template>
  <div class="loginwrapper">
    <h1>{{ $t('reset.title') }}</h1>
    <b-form @submit="reset">
      <b-form-group
        :label="$t('reset.form.email')"
        label-for="email">
        <b-form-input
          id="email"
          v-model="email"
          type="email"
          required>
        </b-form-input>
      </b-form-group>
      <b-button
        type="submit"
        variant="primary"
        block>
        {{ $t('reset.form.button') }}
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { toaster } from '../services/toast'

export default {
  name: 'ThePasswordReset',
  data () {
    return {
      email: ''
    }
  },
  methods: {
    async reset () {
      const initToaster = toaster(this['_bv__toast'])

      try {
        await this.$api.create('reset', { email: this.email })

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))
      } catch ({ data }) {
        initToaster(data.status_code)(this.$t('notifications.error.title'), data.message)

        throw new Error(data.message)
      }
    }
  }
}
</script>
