import Api from '../services/api'
import { SET_CERTIFICATES } from './mutationTypes'

const state = {
  /**
   * @type {Array}
   */
  certificates: [],
  /**
   * @type {{}}
   */
  fields: {}
}

const getters = {
  /**
   *
   * @param state
   *
   * @returns {Array|*|modules.certificates|{namespaced, modules}|RTCCertificate[]}
   */
  list (state) {
    return state.certificates
  },
  /**
   *
   * @param state
   *
   * @returns {{}}
   */
  searchFields (state) {
    return state.fields
  }
}

const mutations = {
  /**
   *
   * @param state
   * @param certificates
   *
   * @returns void
   */
  [SET_CERTIFICATES] (state, certificates) {
    state.certificates = certificates
  },

  /**
   *
   * @param state
   * @param fields
   */
  SET_FIELDS (state, fields) {
    if (fields === null) {
      state.fields = {}
      return
    }
    state.fields = fields
  }
}

const actions = {
  /**
   *
   * @param commit
   * @param options  {Object}
   * @param options.include  {String}  include option: 'owner,productType' => no space between commas
   * @param options.id  {Number}  id option: 10 => id of whatever entity to get
   * @param options.limit  {Number}  limit option: 100 => limit results per page
   * @param options.page  {String}  page option: ?page=2 => next page of a pagination
   * @returns {Promise<void>}
   */
  async fetch ({ commit } = {}, options) {
    const api = new Api()
    let { include } = options
    let { page } = options

    commit('common/loadingSpinner/START_LOADING', null, { root: true })
    commit('setCertificates', await api.get(`certificates`, { params: { page, include } }))
    commit('common/loadingSpinner/END_LOADING', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
