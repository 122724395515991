import { SET_COUNTRIES } from './mutationTypes'
import Api from '../services/api'

const state = {
  countries: [],
  countrySelectItems: []
}

const mutations = {
  /**
   *
   * @param state
   * @param countries
   */
  [SET_COUNTRIES] (state, countries) {
    state.countries = countries.data
  }
}

const getters = {
  /**
   *
   * @param state
   * @returns {getters.countries|(function(*))|default.watch.countries|Array|*|{mutations, state, getters, actions, namespaced}}
   */
  countries (state) {
    return state.countries
  }
}

const actions = {
  /**
   *
   * @param commit
   * @param options
   * @returns {Promise<void>}
   */
  async fetch ({ commit } = {}, options = {}) {
    const api = new Api()
    const { limit } = options

    commit('setCountries', await api.get('countries', { params: { limit } }))
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
