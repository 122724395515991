<template>
  <div class="spinner__container" v-if="isLoading">
    <div class="spinner__container__roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LoadingSpinner',
  computed: {
    ...mapGetters('common/loadingSpinner', ['isLoading'])
  }
}
</script>
