<template>
  <multiselect
    :placeholder="$t('common.selects.productType')"
    track-by="id"
    label="type"
    id="product_type_id"
    :multiple="multiple"
    :options="getOptions"
    v-model="internalState">
  </multiselect>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'ProductTypes',
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
    limitChoices: {
      type: Array,
      default: () => []
    },
    multiple: {
      type: Boolean,
      default: false
    },
    value: {
      type: Number,
      default: 0
    }
  },
  methods: {
    ...mapActions('common/productTypes', ['fetch'])
  },
  mounted () {
    if (this.value > 0) {
      this.internalState = { id: this.value }
    }

    this.fetch({ limit: 500 }).then(() => {
      if ('data' in this.types && this.types.data.length > 0) {
        const type = this.productTypeItems.filter(item => item.id === this.value) || null

        this.internalState = type ? type[0] : null
      }
    })
  },
  data () {
    return {
      internalState: null,
      reducedOptions: []
    }
  },
  components: { Multiselect },
  computed: {
    ...mapGetters('common/productTypes', ['productTypeItems', 'types']),
    getOptions () {
      if (this.limitChoices.length > 0) {
        let availableChoices = []

        for (let choice of this.limitChoices) {
          availableChoices.push(choice.product_type_id)
        }

        return this.productTypeItems.filter(item => availableChoices.includes(item.id))
      }

      return this.productTypeItems
    }
  },
  watch: {
    /**
     * @param value
     */
    internalState (value) {
      if ((value === null || typeof value === 'undefined') && this.productTypeItems.length > 0) {
        this.$emit('input', null)

        return
      }

      this.$emit('input', value.id)
    },
    /**
     * @return {void}
     */
    value () {
      const type = this.productTypeItems.filter(item => item.id === this.value) || null

      this.internalState = type ? type[0] : null
    },
    /**
     * @return {void}
     */
    productTypeItems () {
      if (!this.productTypeItems || !this.value) {
        return
      }

      this.internalState = this.productTypeItems.filter(item => item.id === this.value)[0]
    }
  }
}
</script>
