import Api from '../services/api'
import { SET_OWNERS, SET_FILTERS } from './mutationTypes'

const state = {
  /**
   * @type {{}}
   */
  owners: {},
  /**
   * @type {{}}
   */
  filters: {}
}

const mutations = {
  /**
   *
   * @param state
   * @param payload
   */
  [SET_OWNERS] (state, payload) {
    state.owners = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  [SET_FILTERS] (state, payload) {
    sessionStorage.setItem('owner_filter', JSON.stringify(payload))

    state.filters = payload
  }
}

const getters = {
  /**
   *
   * @param state
   * @returns {Array|getters.owners|(function(*))|*|{mutations, state, getters, actions, namespaced}}
   */
  owners (state) {
    return state.owners
  },

  /**
   *
   * @param state
   * @returns {*}
   */
  filters (state) {
    return state.filters
  }
}

const actions = {
  /**
   *
   * @param commit
   * @param options
   */
  async fetch ({ commit } = {}, options = {}) {
    const api = new Api()
    let {
      id,
      include,
      page,
      query,
      limit,
      sortBy,
      orderBy,
      sort
    } = options

    commit('common/loadingSpinner/START_LOADING', null, { root: true })
    commit('setOwners', await api.get(`owners`, {
      id,
      params: {
        include,
        page,
        ...query,
        limit,
        sortBy,
        orderBy,
        sort
      }
    }))
    commit('common/loadingSpinner/END_LOADING', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
