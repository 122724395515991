const detail = {
  headings: {
    registration_number: 'Identification Code (ID)',
    manufacturer: 'CERTIFICATE HOLDER',
    product_category: 'Product type',
    product_name: 'Type/Model',
    testing_base: 'EN Standard Reference',
    conformity_sign: 'Mark of Conformity',
    expiration_date: 'Valid until',
    technical_data: 'Technical Data',
    document: 'Document',
    additional_information: 'Additional Information',
    certification_body: 'Certification body',
    general_technical_data: 'General technical data',
    data_sheet_link: 'Data sheet reference',
    certificationBody: 'Certification Body'
  },
  text: {
    document: 'Data sheet download',
    document_link: 'Link'
  },
  toolbar: {
    to_results: 'Back to Resultlist',
    update: 'Update certificate',
    new: 'New certificate'
  },
  multiSelect: {
    owner: {
      label: 'Certificate Holder',
      default: 'Select certificate holder'
    },
    cb: {
      label: 'Certification body',
      default: 'Select certification body'
    }
  }
}

export default detail
