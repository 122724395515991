<template>
  <b-container class="the-detail">
    <b-form @submit.stop.prevent="update">
      <b-row>
        <h1 class="pageheading">{{ $t('standards.titles.update') }}</h1>
      </b-row>
      <CRUDToolbar
        :clickFn="update"
        :hasBackButton="false">
      </CRUDToolbar>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('standards.labels.standard') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              v-validate="{ required: true }"
              :class="{'validation--error': veeErrors.has('standard') }"
              name="standard"
              id="standard"
              v-model="content.standard">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('standards.labels.standard_reference') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              v-validate="{ required: true }"
              :class="{'validation--error': veeErrors.has('standard_reference') }"
              name="standard_reference"
              id="standard_reference"
              v-model="content.standard_reference">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('standards.labels.products') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <ProductTypes
              class="multiselect--big"
              :value="content.product_type_id"
              v-on:input="setProductType"
              :showLabel="false">
            </ProductTypes>
          </b-col>
        </b-row>
      </b-row>
      <CRUDToolbar :clickFn="update"></CRUDToolbar>
    </b-form>
  </b-container>
</template>

<script>
import CRUDToolbar from '../components/CRUDToolbar'
import { toaster } from '../services/toast'
import { mapActions } from 'vuex'
import ProductTypes from '../components/Selects/ProductTypes'
export default {
  name: 'TheStandardsUpdate',
  components: { ProductTypes, CRUDToolbar },
  data () {
    return {
      content: {
        standard: ''
      }
    }
  },
  beforeRouteEnter (to, from, next) {
    const { id } = to.params

    if (!id) {
      next(false)
      return
    }

    next(async vue => {
      const { data } = await vue.$api.get(`standards/${id}`)

      vue.content = data
    })
  },
  methods: {
    ...mapActions('common/standards', ['fetch']),
    /**
     *
     * @return {void}
     */
    async update () {
      const initToaster = toaster(this['_bv__toast'])
      const isValid = await this.$validator.validateAll()

      if (isValid === false) return

      try {
        await this.$api.update('standards', this.content.id, this.content)

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))

        await this.fetch({ limit: 500 })

        this.$router.push('/standards/1/list')
      } catch ({ data }) {
        const toaster = initToaster(data.message)

        toaster(this.$t('notifications.error.title'), this.$t(`notifications.error.${data.status_code}`))
      }
    },
    /**
     * @param value
     *
     * @return {void}
     */
    setProductType (value) {
      if (value === null) return

      this.content.product_type_id = value
    }
  }
}
</script>
