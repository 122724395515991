import Api from '../services/api'
import { SET_PRODUCT_TYPES } from './mutationTypes'

const state = {
  /**
   * @type {Array}
   */
  productTypes: [],
  /**
   * @type {Array}
   */
  productTypeSelectItems: []
}

const getters = {
  /**
   *
   * @param state
   * @returns {Array|*|{mutations, state, getters, actions, namespaced}}
   */
  types (state) {
    return state.productTypes
  },
  /**
   *
   * @returns {Array}
   */
  productTypeItems (state, getters) {
    state.productTypeSelectItems = []

    if (typeof getters.types === 'object' && getters.types.hasOwnProperty('data')) {
      for (let data of getters.types.data) {
        state.productTypeSelectItems.push({ ...data })
      }
    }

    return state.productTypeSelectItems
  }
}

const mutations = {
  /**
   *
   * @param state
   * @param types
   */
  [SET_PRODUCT_TYPES] (state, types) {
    state.productTypes = types
  }
}

const actions = {
  /**
   *
   * @param commit
   * @param options  {Object}
   * @param options.include  {String}  include option: 'owner,productType' => no space between commas
   * @param options.id  {Number}  id option: 10 => id of whatever entity to get
   * @param options.limit  {Number}  limit option: 100 => limit results per page
   * @param options.page  {String}  page option: ?page=2 => next page of a pagination
   * @returns {Promise<void>}
   */
  async fetch ({ commit } = {}, options) {
    const api = new Api()
    const { limit, page, query, sortBy, orderBy } = options

    commit('setProductTypes', await api.get(`productTypes`, {
      params: {
        limit,
        page,
        ...query,
        sortBy,
        orderBy
      }
    }))
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
