import Api from './../../../services/api'
import {
  SET_FILTERS,
  SET_TOTAL_PAGES,
  SET_TOTAL_CERTIFICATES,
  SET_CERTIFICATES
} from '../../mutationTypes'

const state = {
  /**
   * Filtered result. Hits array inside of this object
   *
   * @var {Object}
   */
  certificates: {},
  /**
   * @var {int}
   */
  totalPages: 0,
  /**
   * @var {int}
   */
  totalCertificates: 0,
  /**
   * @var {{}}
   */
  filters: {}
}

const getters = {
  /**
   *
   * @param state
   *
   * @returns {{}}
   */
  filters (state) {
    return state.filters
  },

  /**
   *
   * @param state
   * @returns {getters.certificates|(function(*))|Array|state.certificates|{}|*|RTCCertificate[]}
   */
  certificates (state) {
    return state.certificates
  },

  /**
   *
   * @param state
   * @returns {int|getters.totalPages|(function(*))|number}
   */
  totalPages (state) {
    return state.totalPages
  },

  /**
   *
   * @param state
   * @returns {int|getters.totalCertificates|(function(*))|*}
   */
  totalCertificates (state) {
    return state.totalCertificates
  }
}

const mutations = {
  /**
   *
   * @param state
   * @param certificates
   */
  [SET_CERTIFICATES] (state, certificates) {
    state.certificates = certificates
  },

  /**
   *
   * @param state
   * @param limit
   */
  [SET_TOTAL_PAGES] (state, limit = 25) {
    const total = Math.ceil(state.totalCertificates / limit)

    state.totalPages = total <= 0 ? 1 : total
  },

  /**
   *
   * @param state
   * @param total
   */
  [SET_TOTAL_CERTIFICATES] (state, total) {
    state.totalCertificates = total
  },

  /**
   *
   * @param state
   * @param filters
   */
  [SET_FILTERS] (state, filters) {
    sessionStorage.setItem('certificates_filter', JSON.stringify(filters))

    state.filters = filters
  }
}

const actions = {
  /**
   *
   * @param commit
   * @param state
   * @param payload
   * @returns {Promise<void>}
   */
  async lookup ({ commit } = {}, payload = {}) {
    const api = new Api()
    const {
      page = 1,
      limit = 25,
      sort,
      userId,
      sortBy,
      orderBy,
      specifications
    } = payload

    commit('common/loadingSpinner/START_LOADING', null, { root: true })
    const { hits } = await api.get('lookup/certificates', {
      params: {
        ...specifications,
        user_id: userId,
        page,
        limit,
        sort,
        sortBy,
        orderBy
      }
    })

    let { total } = hits

    commit('setTotalCertificates', total.value)
    commit('setTotalPages', limit)
    commit('setCertificates', hits)
    commit('common/loadingSpinner/END_LOADING', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
