<template>
  <multiselect
    :placeholder="$t('common.selects.country')"
    track-by="country"
    label="country"
    id="country_id"
    :options="countries"
    v-model="selectedCountry">
  </multiselect>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Countries',
  props: {
    preselect: {
      type: Number
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions('common/countries', ['fetch'])
  },
  mounted () {
    if ('data' in this.countries === true && this.countries.data.length > 0) return

    this.fetch({ limit: 500 })
  },
  data () {
    return {
      selectedCountry: null
    }
  },
  components: { Multiselect },
  computed: {
    ...mapGetters('common/countries', ['countries'])
  },
  watch: {
    /**
     * @param value
     */
    selectedCountry (value) {
      if (value === null || typeof value === 'undefined') {
        this.$emit('country', null)

        return
      }

      this.$emit('country', value.id)
    },
    /**
     * @param value
     */
    preselect (value) {
      if (this.countries.length === 0) return

      const country = this.countries.filter(({ id }) => id === value) || null

      this.selectedCountry = country ? country[0] : null
    },
    /**
     * @param value
     */
    countries (value) {
      if (!this.preselect) return

      const country = value.filter(({ id }) => id === this.preselect) || null

      this.selectedCountry = country ? country[0] : null
    }
  }
}
</script>
