<template>
  <b-container fluid class="the-results">
    <b-row align-h="between">
      <h1 class="pageheading">{{ $t('owner.titles.title') }}</h1>
      <b-col cols="auto">
        <b-button
          variant="success"
          @click="createOwner">
          {{ $t('myCertificates.toolbar.new') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="auto">
        <b-button-toolbar>
          <b-button-group v-if="selectedOwners.length > 0">
            <b-button
              variant="danger"
              @click="deleteOwner">
              {{ $t('myCertificates.toolbar.delete') }}
            </b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
    </b-row>
    <b-row class="the-results__filterbar">
      <b-form inline>
        <b-form-input
          v-model="filter.name"
          :placeholder='$t("owner.list.placeholder.name")'>
        </b-form-input>
        <Countries
          v-on:country="setCountry"
          :showLabel="false">
        </Countries>
      </b-form>
      <div class="bubble">
        <span> {{ totalPages }} {{ $t('common.hits') }} </span>
      </div>
    </b-row>
    <b-row class="the-results__list">
      <b-table
        selectable
        select-mode="single"
        @row-selected="updateOwner"
        striped
        borderless
        @sort-changed="sortingChanged"
        :no-local-sorting="true"
        hover
        stacked="sm"
        :items="items"
        :fields="fields"
        id="resultlist"
      >
        <template slot="HEAD_select">
          <b-form-checkbox
            @change="checkAll"
            v-model="selectedAll">
          </b-form-checkbox>
        </template>
        <template slot="select" slot-scope="scope">
          <b-form-checkbox
            :value="scope.item.id"
            v-model="selectedOwners"
          ></b-form-checkbox>
        </template>
      </b-table>
    </b-row>
    <b-row class="the-results__toolbar">
      <b-container fluid class="toolbar">
        <b-row align-h="center">
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPages"
              :per-page="perPage"
              @input="fetchNextPage"
            ></b-pagination>

          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <b-row align-h="between">
      <b-col cols="auto">
        <b-button-toolbar>
          <b-button-group v-if="selectedOwners.length > 0">
            <b-button variant="danger" @click="deleteOwner">{{ $t('myCertificates.toolbar.delete') }}</b-button>
          </b-button-group>
        </b-button-toolbar>
      </b-col>
      <b-col cols="auto">
        <b-button variant="success" @click="createOwner">{{ $t('myCertificates.toolbar.new') }}</b-button>
      </b-col>
    </b-row>
    <b-modal
      @ok="handleDeleteOk"
      id="delete_request"
      ref="delete_request">
      <template slot="modal-header">
        <span>{{ $t('owner.modal.title') }}</span> <br>
      </template>
      <template slot="default">
        <span>{{ $t('owner.modal.text') }}</span>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { applyRule } from '../services/request'
import Countries from '../components/Selects/Countries'

export default {
  name: 'TheOwner',
  data () {
    return {
      totalPages: 1,
      perPage: 25,
      filter: {
        name: '',
        country: ''
      },
      selectedOwners: [],
      selectedAll: false,
      currentPage: 1,
      fields: [
        { key: 'select', label: '' },
        { key: 'name', label: this.$t('owner.list.label.name'), sortable: true, class: 'productname' },
        { key: 'country', label: this.$t('owner.list.label.country'), sortable: true, class: 'right-most' }
      ],
      items: [],
      sorting: {
        sortBy: '',
        orderBy: ''
      }
    }
  },
  components: { Countries },
  mounted () {
    if (Object.keys(this.filters).length > 0) {
      this.filter = this.filters
    }

    this.fetchOwners({ query: { ...this.filter } }).then(() => {
      if ('data' in this.owners) {
        this.items = []

        this.pushItems(this.items, this.owners)
      }

      if ('meta' in this.owners) {
        const { pagination } = this.owners.meta

        this.perPage = pagination.per_page
        this.totalPages = pagination.total
      }
    })
  },
  methods: {
    ...mapActions('certificates/owners', { fetchOwners: 'fetch' }),
    ...mapMutations('certificates/owners', ['setFilters']),
    /**
     * @param sortBy
     * @param sortDsc
     *
     * @return {void}
     */
    async sortingChanged ({ sortBy, sortDesc }) {
      this.sorting.orderBy = sortDesc ? 'desc' : 'asc'
      this.sorting.sortBy = sortBy

      let query = {
        search: 1,
        country: this.filter.country_id,
        ...this.filter
      }

      applyRule(query, 'length:3')

      await this.fetchOwners({
        query: Object.keys(Object.deleteEmpty(query)).length === 1 ? {} : query,
        ...this.sorting
      })
    },
    /**
     * @param value
     *
     * @return {void}
     */
    setCountry (value) {
      this.$set(this.filter, 'country', value)
    },
    /**
     *
     * @return {void}
     */
    async fetchNextPage () {
      const sorting = this.sorting.orderBy && this.sorting.sortBy ? this.sorting : null

      await this.fetchOwners({
        page: this.currentPage,
        query: Object.keys(Object.deleteEmpty(this.filter)).length === 1 ? {} : this.filter,
        ...sorting
      })
    },
    /**
     *
     * @return {void}
     */
    deleteOwner () {
      this.$refs['delete_request'].show()
    },
    /**
     * @param items
     *
     * @return {void}
     */
    updateOwner (items) {
      this.$router.push(`/owners/${items[0].id}/update`)
    },
    /**
     *
     * @return {void}
     */
    createOwner () {
      this.$router.push(`/owners`)
    },
    /**
     * @return {void}
     */
    checkAll (state) {
      this.selectedOwners = state ? this.items.map(item => item.id) : []
    },
    /**
     * @param trigger
     *
     * @return {void}
     */
    async handleDeleteOk ({ trigger }) {
      if (trigger !== 'ok') {
        return
      }

      for (let id of this.selectedOwners) {
        await this.$api.delete('owners', id)
      }

      this.selectedOwners = []
      this.selectedAll = false

      this.fetchOwners({ page: this.currentPage, include: 'users' })
    },
    /**
     * @param items
     * @param obj
     *
     * @return {void}
     */
    pushItems (items, obj) {
      for (let data of obj.data) {
        const country = this.countries.filter(country => country.id === data.country_id) || []

        items.push({
          ...data,
          country: country.length ? country[0].country : null
        })
      }
    }
  },
  computed: {
    ...mapGetters('certificates/owners', ['owners', 'filters']),
    ...mapGetters('users/users', ['users']),
    ...mapGetters('common/countries', ['countries'])
  },
  watch: {
    /**
     *
     * @return {void}
     */
    countries () {
      for (let item of this.items) {
        const country = this.countries.filter(country => country.id === item.country_id) || []

        item.country = country.length ? country[0].country : null
      }
    },
    owners: {
      /**
       *
       * @return {void}
       */
      handler: function () {
        this.items = []

        if ('data' in this.owners) {
          this.pushItems(this.items, this.owners)
        }

        if ('meta' in this.owners) {
          let { pagination } = this.owners.meta

          this.perPage = pagination.per_page
          this.totalPages = pagination.total
        }
      },
      deep: true
    },
    filter: {
      /**
       *
       * @param inputs
       *
       * @return {void}
       */
      handler: function (inputs) {
        let query = { search: 1, ...inputs }

        applyRule(query, 'length:3')

        this.fetchOwners({
          query: Object.keys(Object.deleteEmpty(query)).length === 1 ? {} : query
        })

        this.setFilters({ ...query })
      },
      deep: true
    }
  }
}
</script>
