<template>
  <b-row
    v-if="hasProductType"
    class="specifications__collapse">
    <b-col cols="12">
      <span class="collapse__label">
        <b-button @click="showCollapse = !showCollapse">
          <span>
            {{ $t('common.specificationFilter') }}
          </span>
          <img
            :class="[ showCollapse ? 'collapse--down' : 'collapse--up' ]"
            src="../assets/images/streamline-icon-arrow-up-1@24x24.svg" />
        </b-button>
      </span>
    </b-col>
    <b-collapse
      v-model="showCollapse"
      id="collapse-1"
      class="mt-2">
      <b-card>
        <slot></slot>
      </b-card>
    </b-collapse>
  </b-row>
</template>

<script>
export default {
  name: 'TheSpecificationsCollapse',
  data () {
    return {
      showCollapse: true
    }
  },
  props: {
    hasProductType: {
      type: Boolean
    }
  }
}
</script>
