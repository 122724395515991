<template>
  <b-container class="the-detail">
    <b-form @submit.stop.prevent="update">
      <b-row>
        <h1 class="pageheading">{{ $t('productTypes.titles.update') }}</h1>
      </b-row>
      <CRUDToolbar
        :clickFn="update"
        :hasBackButton="false">
      </CRUDToolbar>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('productTypes.labels.productType') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              v-validate="{ required: true }"
              :class="{'validation--error': veeErrors.has('type') }"
              name="type"
              id="type"
              v-model="content.type">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <CRUDToolbar :clickFn="update"></CRUDToolbar>
    </b-form>
  </b-container>
</template>

<script>
import CRUDToolbar from '../components/CRUDToolbar'
import { toaster } from '../services/toast'
import { mapActions } from 'vuex'
export default {
  name: 'TheProductTypesUpdate',
  components: { CRUDToolbar },
  data () {
    return {
      content: {}
    }
  },
  beforeRouteEnter (to, from, next) {
    const { id } = to.params

    if (!id) {
      next(false)
      return
    }

    next(async vue => {
      const { data } = await vue.$api.get(`productTypes/${id}`)

      vue.content = data
    })
  },
  methods: {
    ...mapActions('common/productTypes', ['fetch']),
    /**
     *
     * @return {void}
     */
    async update () {
      const initToaster = toaster(this['_bv__toast'])

      try {
        await this.$api.update(`productTypes`, this.content.id, this.content)

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))

        await this.fetch({ limit: 500 })

        this.$router.push('/productTypes/1/list')
      } catch ({ data }) {
        const toaster = initToaster(data.message)

        toaster(this.$t('notifications.error.title'), this.$t(`notifications.error.${data.status_code}`))
      }
    }
  }
}
</script>
