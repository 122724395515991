<template>
  <div class="loginwrapper">
    <h1>Login</h1>
    <b-form @submit.prevent="login">
      <b-form-group
        :label="$t('login.form.username')"
        label-for="username">
        <b-form-input
          id="username"
          v-model="username"
          type="email"
          required>
        </b-form-input>
      </b-form-group>
      <b-form-group
        :label="$t('login.form.password')"
        label-for="password">
        <b-form-input
          id="password"
          v-model="password"
          type="password"
          required>
        </b-form-input>
      </b-form-group>
      <b-button
        type="submit"
        variant="primary"
        block>
        {{ $t('login.form.login_button') }}
      </b-button>
    </b-form>
    <p>{{ $t('reset.description') }}<br/>
      <router-link :to="{ path: '/reset' }">{{ $t('reset.link') }}</router-link>
    </p>
  </div>
</template>

<script>
import Login from '../services/login'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { toaster } from '../services/toast'
import { getCurrentInstance } from 'vue'

export default {
  name: 'TheLogin',
  /**
   * @returns {{password: string, username: string}}
   */
  data () {
    return {
      username: '',
      password: ''
    }
  },
  computed: {
    ...mapGetters('users/me', ['me'])
  },
  methods: {
    ...mapActions('users/me', ['fetch']),
    ...mapMutations('users/auth', ['setToken']),
    /**
     * @return {Promise<void>}
     */
    async login () {
      const initToaster = toaster(this['_bv__toast'])

      try {
        const token = await Login.init(this.username, this.password)()

        this.setToken(token)

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))
      } catch ({ message }) {
        initToaster(401)(this.$t('notifications.error.title'), message)

        throw new Error(message)
      }

      await this.fetch()

      this.$router.push('/')
    }
  }
}
</script>
