<template>
  <b-container>
    <b-row>
      <h1 class="pageheading">{{ $t('certificateImport.title') }}</h1>
    </b-row>
    <b-row>
      <h2 class="pageheading--secondary">{{ $t('certificateImport.infoHeader') }}</h2>
      <p>
        {{ $t('certificateImport.info') }}
      </p>
    </b-row>
    <b-row>
      <b-col>
        <b-form-file
          v-model="file"
          placeholder="Choose a file..."
          drop-placeholder="Drop file here...">
        </b-form-file>
      </b-col>
      <b-col>
        <b-button
          @click="importCertificate"
          variant="info">
          Import
        </b-button>
      </b-col>
    </b-row>
    <b-row>
      <Logger
        v-if="logger.variant !== null && Array.isArray(logger.messages)"
        :variant="logger.variant"
        :logs="logger.messages" />
    </b-row>
  </b-container>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import jsonToFormData from 'json-form-data'
import Logger from '../components/Logger'

export default {
  data () {
    return {
      file: '',
      logger: {
        variant: null,
        messages: []
      }
    }
  },
  components: { Logger },
  computed: {
    ...mapGetters('users/me', ['me'])
  },
  methods: {
    ...mapMutations('common/loadingSpinner', ['START_LOADING', 'END_LOADING']),
    /**
     *
     * @return {Promise<void>}
     */
    async importCertificate () {
      this.logger.variant = null
      this.logger.messages = []

      if (!this.file) {
        return
      }

      try {
        this.START_LOADING()

        const response = await this.$api.create('import', jsonToFormData({ file: this.file, user_id: this.me.id }))

        if (Array.isArray(response) && response.length > 0) {
          this.END_LOADING()

          this.logger.variant = 'warning'
          this.logger.messages = response

          return
        }

        this.END_LOADING()

        this.$router.push('/me/certificates/1')
      } catch ({ data, status }) {
        this.END_LOADING()

        this.logger.variant = 'error'
        this.logger.messages = data
      }
    }
  }
}
</script>
