const common = {
  phone: 'Phone',
  mail: 'Mail',
  web: 'Web',
  name: 'Name',
  street: 'Street',
  city: 'City',
  country: 'Country',
  hits: 'Hits',
  zip: 'Zip code',
  fax: 'Fax',
  footer: {
    imprint: 'Imprint',
    website: 'Keymark.eu',
    logout: 'Logout',
    login: 'Login'
  },
  back: 'Back',
  browse: 'Browse',
  url: 'URL',
  create: 'Create',
  update: 'Save',
  edit: 'Edit',
  cancel: 'Cancel',
  new: 'New',
  next: 'Next',
  user: 'User',
  specificationFilter: 'FILTER FOR TECHNICAL INFORMATION',
  selects: {
    productType: 'Select product type',
    standard: 'Select EN standard',
    country: 'Select country',
    owner: 'Enter company',
    certificationBody: 'Select Certification Body',
    laboratory: 'Testing laboratory'
  },
  modal: {
    warning: {
      imports: {
        title: 'Importing certificates',
        text: 'Are you sure you want to continue? Be aware that all of your old certificates will be deleted. Make sure your XLS file contains all of your certificates.'
      }
    }
  }
}

export default common
