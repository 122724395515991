<template>
  <b-row class="the-results__list">
    <b-table
      selectable
      select-mode="single"
      @row-selected="navigate"
      striped
      borderless
      hover
      @sort-changed="sortingChanged"
      :no-local-sorting="true"
      stacked="sm"
      :items="itemsCopy"
      :fields="fields"
      id="resultlist">
      <template v-slot:cell(validity_until)="data">
        {{ data.item.validity_until | humanDate }}
      </template>
      <template v-slot:cell(select)="data">
        <b-form-checkbox
          :value="data.item.id"
          v-model="selectedCertificates">
        </b-form-checkbox>
      </template>
    </b-table>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import certificatesTableMixin from '../mixins/certificatesTable'
export default {
  name: 'TheCertificatesListReadTable',
  mounted () {
    this.itemsCopy = this.items
  },
  watch: {
    selectedCertificates (value) {
      this.$emit('selected', value)
    },
    items (value) {
      this.selectedCertificates = []
      this.itemsCopy = value
    }
  },
  data () {
    return {
      selectedCertificates: [],
      fieldsWithSelect: [],
      itemsCopy: null,
      sorting: {
        orderBy: '',
        sortBy: '',
        sort: null
      }
    }
  },
  props: {
    items: {
      type: Array
    },
    fields: {
      type: Array
    },
    userId: {
      type: Number
    },
    filter: {
      type: Object
    }
  },
  mixins: [certificatesTableMixin],
  computed: {
    ...mapGetters('lookups/certificatesLookup', ['certificates'])
  },
  methods: {
    ...mapActions('lookups/certificatesLookup', ['lookup']),
    /**
     * @param certificate {Array}
     *
     * @return {void}
     */
    navigate (certificate) {
      const { id } = certificate[0]
      const { path } = this.$route

      if (path.includes('me')) {
        this.$router.push(`/certificates/${id}/update`)
        return
      }

      this.$router.push(`/certificates/${id}/detail`)
    },
    /**
     * @param sortBy
     * @param sortDesc
     *
     * @return {void}
     */
    async sortingChanged ({ sortBy, sortDesc }) {
      const { path } = this.$route
      let filter = Object.deleteEmpty(this.filter)

      filter['specifications'] = Object.deleteEmpty(filter['specifications'])

      this.sorting.orderBy = sortDesc ? 'desc' : 'asc'
      this.sorting.sortBy = sortBy
      this.sorting.sort = 1

      switch (sortBy) {
        case 'owner_name':
          this.sorting.sortBy = 'owner.name'
          break
        case 'product_type':
          this.sorting.sortBy = 'productType.type'
          break
      }

      sessionStorage.setItem('certificates_sorting', JSON.stringify(this.sorting))

      this.$emit('sort', this.sorting)

      await this.lookup({
        ...this.sorting,
        specifications: filter,
        userId: path.includes('me') ? this.me.id : null
      })

      this.itemsCopy = this.lookupProvider(this.certificates)
    }
  }
}
</script>
