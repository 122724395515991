<template>
  <b-row class="the-results__toolbar">
    <b-container fluid class="toolbar">
      <b-row align-h="between">
        <b-col>
          <b-button
            @click="$router.push('/')"
            variant="outline-primary">
            {{ $t('results.toolbar.new_search') }}
          </b-button>
        </b-col>
        <b-col>
          <b-pagination-nav
            v-if="totalPages > 0"
            :link-gen="paginate"
            :number-of-pages="totalPages"
            use-router
            :no-page-detect="true"
            v-model="currentPage">
          </b-pagination-nav>
        </b-col>
      </b-row>
    </b-container>
  </b-row>
</template>

<script>
export default {
  name: 'TheCertificatesListPagination',
  data () {
    return {
      currentPage: 1
    }
  },
  mounted () {
    const params = this.$route.params

    this.currentPage = Number(params.id)
  },
  props: {
    totalPages: {
      type: Number
    },
    readOnly: {
      type: Boolean
    }
  },
  watch: {
    totalPages (page) {
      const path = this.readOnly === true ? `/certificates/${page}/list` : `/me/certificates/${page}`

      if (this.currentPage > page) {
        this.currentPage = page
        this.$router.push(path)
      }
    }
  },
  methods: {
    /**
     * @param page
     *
     * @return {{}}
     */
    paginate (page) {
      const path = this.readOnly === true ? `/certificates/${page}/list` : `/me/certificates/${page}`
      return { path }
    }
  }
}
</script>
