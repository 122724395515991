import { SET_LABORATORIES } from './mutationTypes'
import Api from '../services/api'

const state = {
  /**
   * @var {Array}
   */
  laboratories: []
}
const getters = {
  /**
   *
   * @param state
   * @returns {getters.laboratories|(function(*))|Array|*}
   */
  laboratories (state) {
    return state.laboratories
  }
}
const mutations = {
  /**
   *
   * @param state
   * @param laboratories
   */
  [SET_LABORATORIES] (state, laboratories) {
    state.laboratories = laboratories
  }
}
const actions = {
  /**
   *
   * @param commit
   * @param options
   * @returns {Promise<void>}
   */
  async fetch ({ commit } = {}, options = {}) {
    const api = new Api()
    let {
      id,
      include,
      page,
      query,
      limit,
      sortBy,
      orderBy,
      sort
    } = options

    commit('common/loadingSpinner/START_LOADING', null, { root: true })
    commit('setLaboratories', await api.get(`laboratories`, {
      id,
      params: {
        include,
        page,
        ...query,
        limit,
        sortBy,
        orderBy,
        sort
      } }))
    commit('common/loadingSpinner/END_LOADING', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
