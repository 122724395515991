<template>
  <b-container fluid class="the-results">
    <b-row align-h="between">
      <h1 class="pageheading">{{ $t('results.title') }}</h1>
    </b-row>
    <TheCertificatesListFilter
      :productTypeId="Number($route.query.product_type_id)"
      v-on:filter="setFilter" />
    <BubbleCount>
      <template>
        <span>
          {{ totalCertificates }} {{ $t('common.hits') }}
        </span>
      </template>
    </BubbleCount>
    <b-row align-h="between">
      <TheCertificatesCRUDToolbar
        :productType="filter.product_type"
        :filters="filter"
        :certificates="selected"
        v-if="me.id && $route.path.includes('me')" />
    </b-row>
    <b-row>
      <b-col
        class="p-1"
        v-if="selected.length > 0">
        <b-button
          variant="danger"
          v-if="me.id && $route.path.includes('me')"
          @click="$refs['delete_request'].show()">
          {{ $t('myCertificates.toolbar.delete') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row align-h="between">
      <TheCertificatesReadToolbar
        :productType="filter.product_type"
        :filters="filter"
        :certificates="selected"/>
    </b-row>
    <TheCertificatesListReadTable
      v-if="items && !$route.path.includes('me')"
      :filter="filter"
      :userId="me.id"
      v-on:selected="setSelected"
      v-on:sort="sorting = $event"
      :items="items"
      :fields="fields" />
    <TheCertificatesCRUDTable
      :filter="filter"
      :userId="me.id"
      :isPowerUser="isPowerUser"
      v-on:selected="setSelected"
      v-on:sort="sorting = $event"
      v-if="items && $route.path.includes('me')"
      :items="items"
      :fields="fields" />
    <TheCertificatesListPagination
      :readOnly="!$route.path.includes('me')"
      :totalPages="totalPages"/>
    <b-modal
      v-if="$route.path.includes('me') && me.id"
      @ok="deleteCertificates"
      id="delete_request"
      ref="delete_request">
      <template slot="modal-header">
        <span>{{ $t('myCertificates.modal.title') }}</span> <br>
      </template>
      <template slot="default">
        <span>{{ $t('myCertificates.modal.text') }}</span>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import certificatesTableMixin from '../mixins/certificatesTable'
import TheCertificatesListFilter from '../components/TheCertificatesListFilter'
import BubbleCount from '../components/BubbleCount'
import TheCertificatesListPagination from '../components/TheCertificatesListPagination'
import TheCertificatesListReadTable from '../components/TheCertificatesListReadTable'
import TheCertificatesCRUDTable from '../components/TheCertificatesCRUDTable'
import TheCertificatesCRUDToolbar from '../components/TheCertificatesCRUDToolbar'
import TheCertificatesReadToolbar from '@/components/TheCertificatesReadToolbar'

export default {
  name: 'TheCertificatesList',
  components: {
    TheCertificatesReadToolbar,
    TheCertificatesCRUDToolbar,
    TheCertificatesCRUDTable,
    TheCertificatesListReadTable,
    TheCertificatesListPagination,
    BubbleCount,
    TheCertificatesListFilter
  },
  data () {
    return {
      filter: {},
      sorting: {},
      selected: [],
      productCategories: [
        {
          text: this.$t('search.form.certificate_search.placeholder.product_category'),
          value: null
        }
      ],
      fields: [
        { key: 'select', label: '' },
        { key: 'owner_name', label: this.$t('results.list.certificate_holder'), sortable: true },
        { key: 'product_type', label: this.$t('results.list.product_category'), sortable: true },
        { key: 'product_name', label: this.$t('results.list.product_name'), sortable: true },
        { key: 'certificate_nr', label: this.$t('results.list.registration_number'), sortable: true },
        { key: 'validity_until', label: this.$t('results.list.expiration_date'), sortable: true }
      ],
      items: []
    }
  },
  mixins: [certificatesTableMixin],
  mounted () {
    if (this.$route.query.restore === 'true') {
      const storage = JSON.parse(sessionStorage.getItem('certificates_sorting'))

      if (storage) {
        this.sorting = storage
      }
    }
  },
  watch: {
    filter: {
      /**
       *
       * @param inputs
       */
      handler: function (inputs) {
        const { path, params } = this.$route
        let filter = { ...inputs }

        filter['specifications'] = Object.deleteEmpty(filter['specifications'])

        sessionStorage.setItem('certificates_filter', JSON.stringify(filter))

        this.lookup({
          ...this.sorting,
          page: params.id,
          specifications: filter,
          userId: path.includes('me') && !this.isPowerUser ? this.me.id : null
        })
          .then(() => {
            this.items = this.lookupProvider(this.certificates)
          })
      },
      deep: true
    }
  },
  /**
   *
   * @param to
   * @param from
   * @param next
   *
   * @return {void}
   */
  beforeRouteUpdate (to, from, next) {
    const { id } = to.params
    let filter = Object.deleteEmpty(this.filter)

    filter['specifications'] = Object.deleteEmpty(filter['specifications'])

    this.lookup({
      ...this.sorting,
      page: id,
      specifications: filter,
      userId: to.path.includes('me') && !this.isPowerUser ? this.me.id : null
    })
      .then(() => {
        this.items = this.lookupProvider(this.certificates)
      })

    next()
  },
  methods: {
    ...mapActions('lookups/certificatesLookup', ['lookup']),
    setFilter (value) {
      if (value === null) return

      this.filter = value
    },
    setSelected (value) {
      this.selected = value
    },
    /**
     *
     * @param trigger
     *
     * @return {Promise<void>}
     */
    async deleteCertificates ({ trigger }) {
      if (trigger !== 'ok') {
        return
      }

      for (let id of this.selected) {
        await this.$api.delete('certificates', id)
        await this.$api.create('lookup/refresh/certificates')
      }

      // Emit this here and refresh at the parent
      await this.lookup({
        userId: this.$route.path.includes('me') && !this.isPowerUser ? this.me.id : null,
        page: this.$route.params.page
      })
        .then(() => {
          this.items = this.lookupProvider(this.certificates)
        })
    }
  },
  computed: {
    ...mapGetters('users/me', ['me', 'isPowerUser']),
    ...mapGetters('lookups/certificatesLookup', ['certificates', 'totalPages', 'totalCertificates'])
  }
}
</script>
