<template>
  <b-container fluid class="the-results">
    <ListToolbar
      :endpoint="'certificationBodies'"
      :page="currentPage"
      :heading="$t('certificationBody.titles.title')"
      :items="selectedCertificationBodies"/>
    <b-row class="the-results__filterbar">
      <SearchToolbar
        :namespace="'certificationBody'"
        :inputList="['name', 'registrationNumber']">
        <template v-slot:country>
          <Countries
            v-on:country="setCountry"
            :preselect="preselectedCountry"
            :showLabel="false">
          </Countries>
        </template>
        <template v-slot:product-type>
          <ProductTypes
            class="multiselect--big"
            v-on:input="setProductType"
            :value="preselectedProductType"
            v-show="!$route.query.product_type_id || parseInt($route.query.product_type_id) < 1"
            :showLabel="false">
          </ProductTypes>
        </template>
      </SearchToolbar>
      <div class="bubble">
        <span> {{ totalPages }} {{ $t('common.hits') }} </span>
      </div>
    </b-row>
    <ListToolbarDeleteButton :isVisible="selectedCertificationBodies.length > 0"/>
    <b-row class="the-results__list">
      <SearchTable
        v-on:sort="sorting = $event"
        v-on:select="setCertificationBodies"
        :items="items"
        :fields="fields" />
    </b-row>
    <b-row class="the-results__toolbar">
      <b-container fluid class="toolbar">
        <b-row align-h="center">
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPages"
              :per-page="perPage"
              @input="fetchNextPage">
            </b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <ListToolbar
      :endpoint="'certificationBodies'"
      :page="currentPage"
      :items="selectedCertificationBodies"/>
    <b-row class="the-results__toolbar">
      <b-container fluid class="toolbar">
        <b-row align-h="between">
          <b-col>
            <b-button
              @click="$router.push('/')"
              variant="outline-primary">
              {{ $t('results.toolbar.new_search') }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <error
      v-on:delete="refreshCertificationBodies"
      :fetch="fetch"
      :items="selectedCertificationBodies"
      :endpoint="'certificationBodies'"
      :title="$t('myCertificates.modal.title')"
      :text="$t('myCertificates.modal.text')" />
  </b-container>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import { applyRule } from '../services/request'
import ProductTypes from '../components/Selects/ProductTypes'
import Countries from '../components/Selects/Countries'
import SearchToolbar from '../components/ListSearchBar'
import { _underscore } from '@suben/keys_transformer'
import SearchTable from '../components/ListTable'
import ListToolbar from '../components/ListToolbar'
import ListToolbarDeleteButton from '../components/ListToolbarDeleteButton'
import Error from '../components/modals/error'

export default {
  name: 'TheCertificationBodiesList',
  components: {
    Error,
    ListToolbarDeleteButton,
    ListToolbar,
    SearchTable,
    SearchToolbar,
    ProductTypes,
    Countries
  },
  data () {
    return {
      totalPages: 1,
      perPage: 25,
      selectedCertificationBodies: [],
      currentPage: 1,
      fields: [
        { key: 'select', label: '' },
        { key: 'name', label: this.$t('certificationBody.list.label.name'), sortable: true, class: 'productname' },
        { key: 'registration_number', label: this.$t('certificationBody.list.label.registrationNumber'), sortable: true },
        { key: 'country', label: this.$t('certificationBody.table.country'), sortable: true },
        { key: 'productType', label: this.$t('certificationBody.table.productType') }
      ],
      items: [],
      sorting: {
        sortBy: '',
        orderBy: ''
      },
      resetSearch: true
    }
  },
  /**
   * @return void
   */
  mounted () {
    const { query } = this.$route

    if (query.restore === 'true') {
      this.SET_SEARCH_VALUES(this.searchFilter)
      const storage = JSON.parse(sessionStorage.getItem('certification_bodies_sorting'))

      if (storage) {
        this.sorting = storage
      }
    }

    if (query.product_type_id && query.product_type_id > 0) {
      this.fields.pop()
    }

    if ('meta' in this.certificationBodies) {
      const { pagination } = this.certificationBodies.meta

      this.perPage = pagination.per_page
      this.totalPages = pagination.total
    }
  },
  methods: {
    ...mapActions('common/certificationBodies', ['fetch']),
    ...mapMutations('common/certificationBodies', ['setFilters']),
    ...mapMutations('common/search', ['SET_SEARCH_VALUES']),
    /**
     * @param value
     *
     * @return {void}
     */
    setCertificationBodies (value) {
      this.selectedCertificationBodies = value
    },
    /**
     * @param value
     *
     * @return {void}
     */
    async refreshCertificationBodies (value) {
      await this.fetch({ page: this.currentPage, include: 'productTypes' })
    },
    /**
     * @param value
     *
     * @return {void}
     */
    setProductType (value) {
      const { query } = this.$route

      if (query.product_type_id) {
        value = Number(query.product_type_id)
      }

      this.SET_SEARCH_VALUES({ ...this.searchValues, productType: value })
    },
    /**
     * @param value
     *
     * @return {void}
     */
    setCountry (value) {
      this.SET_SEARCH_VALUES({ ...this.searchValues, country: value })
    },
    /**
     * @return void
     */
    async fetchNextPage () {
      const sorting = this.sorting.orderBy && this.sorting.sortBy ? this.sorting : null
      let query = Object.deleteEmpty(this.searchValues)

      await this.fetch({
        page: this.currentPage,
        include: 'productTypes',
        query: _underscore(query),
        ...sorting
      })
    },
    /**
     * @param items
     * @param obj
     *
     * @return {void}
     */
    pushItems (items, obj) {
      for (let data of obj.data) {
        const country = this.countries.filter(country => country.id === data.country_id) || []
        const types = data.productTypes.data.map(item => item.type)

        items.push({
          id: data.id,
          name: data.name,
          registration_number: data.registration_number,
          country: country.length ? country[0].country : null,
          country_id: data.country_id,
          productType: data.productTypes.data.length > 0 ? types.join(', ') : null
        })
      }
    }
  },
  computed: {
    ...mapGetters('common/certificationBodies', ['certificationBodies', 'searchFilter']),
    ...mapGetters('common/countries', ['countries']),
    ...mapGetters('users/auth', ['token']),
    ...mapGetters('users/me', ['me', 'isPowerUser', 'isAdmin']),
    ...mapGetters('common/search', ['searchValues']),
    preselectedProductType () {
      return this.searchValues.productType || null
    },
    preselectedCountry () {
      return this.searchValues.country || null
    }
  },
  watch: {
    /**
     * @return {void}
     */
    countries () {
      if (!this.items.length) return

      for (let item of this.items) {
        item.country = this.countries.filter(country => country.id === item.country_id)[0].country
      }
    },
    certificationBodies: {
      handler: function () {
        this.items = []

        if ('data' in this.certificationBodies) {
          this.pushItems(this.items, this.certificationBodies)
        }

        if ('meta' in this.certificationBodies) {
          let { pagination } = this.certificationBodies.meta

          this.perPage = pagination.per_page
          this.totalPages = pagination.total
        }
      },
      deep: true
    },
    resetSearch (value) {
      if (!value) return

      this.fetch({
        include: 'productTypes',
        page: this.currentPage
      })
    },
    searchValues: {
      handler: function (value) {
        const sorting = this.sorting.orderBy && this.sorting.sortBy ? this.sorting : null
        let query = Object.deleteEmpty({ search: 1, ...value })

        if (Object.keys(query).length === 1) {
          query = {}
        }

        applyRule(query, 'length:3')

        if (Object.keys(query).length === 1) {
          this.resetSearch = true

          return
        }

        this.resetSearch = false
        this.setFilters(query)

        this.fetch({
          query: _underscore(query),
          include: 'productTypes',
          page: this.currentPage,
          ...sorting
        })
      },
      deep: true
    }
  }
}
</script>
