<template>
  <b-container class="the-detail">
    <b-form @submit.stop.prevent="update">
      <b-row align-h="between">
        <h1 class="pageheading">{{ $t('laboratories.titles.update') }}</h1>
        <CRUDToolbar
          :clickFn="update"
          :hasBackButton="false">
        </CRUDToolbar>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.name') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              v-validate="{ required: true }"
              :class="{'validation--error': veeErrors.has('name') }"
              name="name"
              id="name"
              v-model="content.name">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.street') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="street"
              v-model="content.street">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.zip') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="zip"
              v-model="content.zip">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.location') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="location"
              v-model="content.location">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.country') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <Countries
              :preselect="content.country_id"
              v-on:country="setCountry"
              :showLabel="false">
            </Countries>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.phone') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              name="phone"
              id="phone"
              v-model="content.phone">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <CRUDToolbar :clickFn="update"></CRUDToolbar>
    </b-form>
  </b-container>
</template>

<script>
import CRUDToolbar from '../components/CRUDToolbar'
import Countries from '../components/Selects/Countries'
import FormsMixin from '../mixins/forms'
import { toaster } from '../services/toast'
import { mapActions } from 'vuex'

export default {
  name: 'TheLaboratoriesUpdate',
  components: { CRUDToolbar, Countries },
  data () {
    return {
      content: {}
    }
  },
  mixins: [FormsMixin],
  /**
   * @param to
   * @param from
   * @param next
   *
   * @return {void}
   */
  beforeRouteEnter (to, from, next) {
    const { id } = to.params

    if (!id) {
      next(false)
      return
    }

    next(async vue => {
      const { data } = await vue.$api.get(`laboratories/${id}`)

      vue.content = data
    })
  },
  methods: {
    ...mapActions('common/laboratories', ['fetch']),
    /**
     *
     * @return {Promise<void>}
     */
    async update () {
      const initToaster = toaster(this['_bv__toast'])
      const isValid = await this.$validator.validateAll()

      if (isValid === false) return

      let requestBody = { ...this.content }

      try {
        await this.$api.update(`laboratories`, this.content.id, requestBody)

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))

        await this.fetch({ limit: 500 })

        this.$router.push('/laboratories/1/list')
      } catch ({ data }) {
        const toaster = initToaster(data.message)

        toaster(this.$t('notifications.error.title'), this.$t(`notifications.error.${data.status_code}`))
      }
    },
    setCountry (value) {
      this.content.country_id = value
    }
  }
}
</script>
