<template>
  <b-modal
    header-bg-variant="danger"
    header-text-variant="light"
    ok-variant="danger"
    cancel-variant="outline-info"
    @ok="handleDeleteOk"
    id="error_modal"
    ref="error_modal">
    <template slot="modal-header">
      <span>{{ title }}</span> <br>
    </template>
    <template slot="default">
      <span><b>{{ text }}</b></span>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'error',
  props: {
    title: String,
    text: String,
    items: {
      type: Array
    },
    endpoint: {
      type: String
    }
  },
  methods: {
    /**
     * @param trigger
     *
     * @return {void}
     */
    async handleDeleteOk ({ trigger }) {
      if (trigger !== 'ok') {
        return
      }

      for (let id of this.items) {
        await this.$api.delete(this.endpoint, id)

        this.$emit('delete', id)
      }
    }
  }
}
</script>
