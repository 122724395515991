<template>
  <section class="search search--certificate-search">
    <b-form
      @submit.prevent="search"
      @reset.prevent="reset(form)">
      <fieldset>
        <legend>{{ $t('search.form.certificate_search.heading.product') }}</legend>
        <b-form-group
          :label='$t("search.form.certificate_search.label.product_name")'
          label-for="product_name">
          <b-form-input
            id="product_name"
            type="text"
            v-model="form.product_name"
            :placeholder='$t("search.form.certificate_search.placeholder.product_name")'>
          </b-form-input>
        </b-form-group>
        <ProductTypes class="multiselect--big" v-on:input="setProductType"></ProductTypes>
      </fieldset>
      <fieldset>
        <legend>{{ $t('search.form.certificate_search.heading.certificate') }}</legend>
        <b-form-group
          :label='$t("search.form.certificate_search.label.certificate_number")'
          label-for="certificate_number">
          <b-form-input
            id="certificate_number"
            type="text"
            v-model="form.certificate_nr"
            :placeholder='$t("search.form.certificate_search.placeholder.certificate_number")'>
          </b-form-input>
        </b-form-group>
        <b-form-group
          :label='$t("search.form.certificate_search.label.standard_reference")'
          label-for="standard_reference">
          <Standards
            v-model="form.standard"
            :productTypeId="form.product_type">
          </Standards>
        </b-form-group>
        <b-form-group
          :label='$t("search.form.certificate_search.label.body_name")'
          label-for="body_name">
          <CertificationBodies v-on:certificationBody="setCertificationBody"/>
        </b-form-group>
      </fieldset>
      <fieldset>
        <legend>{{ $t('search.form.certificate_search.heading.holder') }}</legend>
        <b-form-group
          :label='$t("search.form.certificate_search.label.manufacturer")'
          label-for="manufacturer">
          <b-form-input
            id="manufacturer"
            type="text"
            v-model="form.owner_name"
            :placeholder='$t("search.form.certificate_search.placeholder.manufacturer")'>
          </b-form-input>
        </b-form-group>
        <b-form-group
          :label='$t("search.form.certificate_search.label.manufacturer_country")'
          label-for="manufacturer_country">
          <Countries v-on:country="setCountry"></Countries>
        </b-form-group>
      </fieldset>
      <b-container fluid class="search__toolbar">
        <b-row align-h="end">
          <b-button
            v-if="enableResetButton"
            type="reset"
            variant="link">Reset</b-button>
          <b-button
            type="submit"
            variant="primary">Submit</b-button>
        </b-row>
      </b-container>
    </b-form>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import FormsMixin from '../mixins/forms'
import ProductTypes from './Selects/ProductTypes'
import Standards from './Selects/Standards'
import Countries from './Selects/Countries'
import CertificationBodies from './Selects/CertificationBodies'

export default {
  name: 'TheCertificateSearch',
  data () {
    return {
      form: {
        product_name: '',
        product_type: null,
        certificate_nr: '',
        standard: null,
        certification_body_name: '',
        owner_name: '',
        owner_country: '',
        specifications: {}
      }
    }
  },
  components: { CertificationBodies, ProductTypes, Standards, Countries },
  mixins: [FormsMixin],
  computed: {
    ...mapGetters('common/standards', ['standards', 'standardItems']),
    /**
     * @return {boolean}
     */
    enableResetButton () {
      return Object.values(this.form).filter(value => value !== '' && value !== null).length > 0
    }
  },
  methods: {
    ...mapActions('lookups/certificatesLookup', ['lookup']),
    ...mapMutations('certificates/searchResults', ['SET_FIELDS']),
    /**
     * @param value
     *
     * @return {void}
     */
    setProductType (value) {
      this.form.product_type = value
    },
    setCertificationBody (value) {
      this.form.certification_body_id = value
    },
    /**
     * @param value
     *
     * @return {void}
     */
    setCountry (value) {
      this.form.owner_country = value
    },
    /**
     *
     * @return {Promise<void>}
     */
    async search () {
      const firstPage = 1

      sessionStorage.removeItem('certificates_filter')
      this.SET_FIELDS(Object.deleteEmpty(this.form))

      this.$router.push(`/certificates/${firstPage}/list`)
    }
  }
}
</script>
