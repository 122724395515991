import Api from './api'

const login = {
  /**
   *
   * @param email
   * @param password
   * @returns {function()}
   */
  init (email, password) {
    const requestBody = {
      email,
      password
    }
    const api = new Api()

    return async () => {
      let userToken = ''
      try {
        const { access_token: token } = await api.create('auth/login', requestBody)

        userToken = token
      } catch (e) {
        throw e
      }

      if (typeof userToken === 'undefined' || userToken.length === 0) {
        throw new Error('Token could not been fetched')
      }

      return userToken
    }
  },
  /**
   *
   * @returns {Promise<void>}
   */
  async refresh () {
    const api = new Api()

    const { token } = await api.create('auth/refresh')
      .catch(() => {
        throw new Error('Token could not been refreshed.')
      })

    if (typeof token === 'undefined' || token.length === 0) {
      throw new Error('Token could not been fetched')
    }

    return token
  }
}

export default login
