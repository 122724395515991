<template>
  <b-container class="the-detail specifications">
    <b-form
      v-if="startPage"
      @submit.stop.prevent="create">
      <b-row class="detailsection detailsection--registrationnumber">
        <h3 class="detailsection__heading">{{ $t('detail.headings.registration_number') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              v-validate="{ required: true }"
              :class="{'validation--error': veeErrors.has('certificateNumber') }"
              name="certificateNumber"
              v-model="baseInfo.certificate_nr">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row
        v-if="isPowerUser"
        class="detailsection detailsection--certificationBody"
      >
        <h3 class="detailsection__heading">{{ $t('detail.headings.certification_body') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <CertificationBodies v-on:certificationBody="setCertificationBody"/>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('detail.headings.product_category') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <ProductTypes
              class="multiselect--big"
              :limitChoices="limitChoices"
              v-on:input="setProductType"
              :required="true">
            </ProductTypes>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productname">
        <span class="detailsection__heading">{{ $t('detail.headings.product_name') }}</span>
        <b-row class="detailsection__content">
          <b-col>
            <b-input
              v-validate="{ required: true }"
              :class="{'validation--error': veeErrors.has('productName') }"
              name="productName"
              v-model="baseInfo.product_name">
            </b-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--testingbase">
        <h3 class="detailsection__heading">{{ $t('detail.headings.testing_base') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <p>
              <Standards
                v-model="baseInfo.standard_id"
                :productTypeId="productTypeId"
                :required="true">
              </Standards>
            </p>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--conformitysign">
        <h3 class="detailsection__heading">{{ $t('detail.headings.conformity_sign') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <ConformitySigns
              v-model="baseInfo.conformity_sign_id"
              :productTypeId="baseInfo.product_type_id" />
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection-expirationdate">
        <h3 class="detailsection__heading">{{ $t('detail.headings.expiration_date') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-input
              v-validate="{ required: true }"
              :class="{'validation--error': veeErrors.has('validity_until') }"
              name="validity_until"
              type="date"
              v-model="baseInfo.validity_until">
            </b-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection-expirationdate">
        <h3 class="detailsection__heading">{{ $t('detail.headings.general_technical_data') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-textarea
              name="general_technical_data"
              type="text"
              v-model="baseInfo.general_technical_data">
            </b-form-textarea>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--owner">
        <h3 class="detailsection__heading">{{ $t('detail.multiSelect.owner.label') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <Owners v-on:owner="setOwner"></Owners>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--document">
        <h3 class="detailsection__heading">{{ $t('detail.headings.document') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-file
              accept="application/pdf"
              v-model="baseInfo.data_sheet"
              placeholder="Choose a file..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection-expirationdate">
        <h3 class="detailsection__heading">{{ $t('detail.headings.data_sheet_link') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-input
              name="data_sheet_link"
              type="text"
              v-model="baseInfo.data_sheet_link">
            </b-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row
        align-h="between"
        class="the-detail__toolbar">
        <b-col cols="auto">
          <b-button
            variant="outline-primary"
            @click="$router.go(-1)">
            {{ $t('common.back') }}
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button
            type="submit"
            variant="success">
            {{ hasSpecifications ? $t('common.next') : $t('common.new') }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <TheSpecifications
      v-if="!startPage && hasSpecifications"
      :certificateId="certificateId" />
  </b-container>
</template>
<script>
import jsonToFormData from 'json-form-data'
import { mapGetters, mapActions } from 'vuex'
import ProductTypes from '../components/Selects/ProductTypes'
import Standards from '../components/Selects/Standards'
import Owners from '../components/Selects/Owners'
import { appendCustomRequired } from '../services/request'
import TheSpecifications from '../components/TheSpecifications'
import CertificationBodies from '../components/Selects/CertificationBodies'
import ConformitySigns from '../components/ConformitySigns'

export default {
  name: 'TheCertificatesCreate',
  data () {
    return {
      baseInfo: {},
      limitChoices: [],
      startPage: true,
      certificateId: null,
      productTypeId: null,
      person: {},
      hasSpecifications: false
    }
  },
  components: {
    ConformitySigns,
    CertificationBodies,
    TheSpecifications,
    Owners,
    ProductTypes,
    Standards
  },
  computed: {
    ...mapGetters('certificates/certificateDetails', ['details']),
    ...mapGetters('users/me', ['isPowerUser']),
    ...mapGetters('certificates/specifications', ['specifications'])
  },
  methods: {
    ...mapActions('certificates/specifications', ['fetch']),
    setCertificationBody (value) {
      this.baseInfo.certification_body_id = value
    },
    /**
     * @param value
     *
     * @return {void}
     */
    async setProductType (value) {
      if (value === null) return

      this.baseInfo.product_type_id = value
      this.productTypeId = value

      try {
        await this.fetch(value)

        this.hasSpecifications = this.specifications.length > 0
      } catch ({ data }) {
        const { message } = data

        if (message === 'Type not found') {
          this.hasSpecifications = false
        }
      }
    },
    /**
     * @return {void}
     */
    async create () {
      const selectError = appendCustomRequired([
        'product_type_id',
        'standard_id',
        'owner_id'
      ], 'multiselect', this.baseInfo)
      const isValid = await this.$validator.validateAll()

      if (selectError === true || isValid === false) return

      try {
        const { data } = await this.$api.create('certificates', jsonToFormData(Object.deleteEmpty(this.baseInfo)))

        await this.$api.create('lookup/refresh/certificates')

        this.certificateId = data.id
        this.startPage = false

        if (!this.hasSpecifications) {
          this.$router.push('/me/certificates/1')
        }
      } catch (e) {
        this.startPage = true
      }
    },
    /**
     * @param value
     *
     * @return {void}
     */
    setOwner (value) {
      this.baseInfo.owner_id = value
    }
  }
}
</script>
