<template>
  <b-form
    inline
    v-if="keys.length > 0">
    <b-form-input
      v-for="(key, index) in keys"
      :key="index"
      :placeholder='$t(`${namespace}.list.placeholder.${key}`)'
      v-model="inputs[key]">
    </b-form-input>
    <slot v-on:input="setCountry" name="country"></slot>
    <slot v-on:input="setProductType" name="product-type"></slot>
  </b-form>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'ListSearchBar',
  data () {
    return {
      inputs: {}
    }
  },
  mounted () {
    this.inputList.forEach(item => {
      this.$set(this.inputs, item, null)
    })
  },
  computed: {
    ...mapGetters('common/search', ['searchValues']),
    keys () {
      return Object.keys(this.inputs)
    }
  },
  props: {
    namespace: {
      type: String
    },
    inputList: {
      type: Array
    }
  },
  watch: {
    inputs: {
      handler: function (value) {
        if (!Object.keys(Object.deleteEmpty(value)).length) return

        this.SET_SEARCH_VALUES(value)
      },
      deep: true
    },
    searchValues: {
      handler: function (value) {
        // Only do this once
        if (!Object.keys(this.inputs).length) return
        const inputKeys = Object.keys(this.inputs)
        const searchValueKeys = Object.keys(value)

        searchValueKeys.forEach(key => {
          if (inputKeys.includes(key)) {
            this.inputs[key] = this.searchValues[key]
          }
        })
      }
    },
    deep: true
  },
  methods: {
    ...mapMutations('common/search', ['SET_SEARCH_VALUES'])
  }
}
</script>
