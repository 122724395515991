<template>
  <b-button
    variant="danger"
    v-if="isVisible"
    @click="makeDelete">
    {{ $t('myCertificates.toolbar.delete') }}
  </b-button>
</template>

<script>
export default {
  name: 'ListToolbarDeleteButton',
  methods: {
    /**
     * @return void
     */
    makeDelete () {
      this.$bvModal.show('error_modal')
    }
  },
  props: {
    isVisible: {
      type: Boolean
    }
  }
}
</script>
