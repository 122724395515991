<template>
  <b-row align-h="between">
    <h1
      v-if="heading"
      class="pageheading">{{ heading }}</h1>
    <b-col cols="auto" v-if="!heading">
      <ListToolbarDeleteButton :isVisible="items.length > 0" />
    </b-col>
    <b-col
      cols="auto"
      v-if="isAdmin || isPowerUser">
      <b-button
        variant="success"
        @click="createView">
        {{ $t('myCertificates.toolbar.new') }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ListToolbarDeleteButton from './ListToolbarDeleteButton'

export default {
  name: 'ListToolbar',
  components: { ListToolbarDeleteButton },
  props: {
    items: {
      type: Array
    },
    heading: {
      type: String
    },
    endpoint: {
      type: String
    }
  },
  computed: {
    ...mapGetters('users/me', ['isPowerUser', 'isAdmin'])
  },
  methods: {
    ...mapActions('common/certificationBodies', ['fetch']),
    /**
     * @return void
     */
    createView () {
      this.$router.push(`/${this.endpoint}`)
    }
  }
}
</script>
