const certificateImport = {
  title: 'Certificate import',
  infoHeader: 'Before you get started:',
  info: `The algorithm of this import removes all of your certificates, which might have been stored into the database already.
  This approach prevents storing a certificate twice or more. Due to this "issue",
  we want you to make sure,
  that your XLS file contains all of your certificates. Even those, that might be in the database already.`
}

export default certificateImport
