<template>
  <section class="search search--certificate-search">
    <b-form @submit.prevent="search" @reset.prevent="reset(filter)">
      <fieldset class="no-border">
        <legend>{{ $t("certificationBody.list.label.name") }}</legend>
        <b-form-input
          :placeholder="$t('certificationBody.list.placeholder.name')"
          v-model="filter.name">
        </b-form-input>
      </fieldset>
      <fieldset class="no-border">
        <legend>{{ $t("certificationBody.list.label.registrationNumber") }}</legend>
        <b-form-input
          :placeholder="$t('certificationBody.list.placeholder.registrationNumber')"
          v-model="filter.registrationNumber">
        </b-form-input>
      </fieldset>
      <fieldset class="no-border">
        <legend>{{ $t("certificationBody.list.label.country") }}</legend>
        <Countries
          v-on:country="setCountry"
          :showLabel="false">
        </Countries>
      </fieldset>
      <fieldset class="no-border">
        <legend>{{ $t("certificationBody.list.label.productType") }}</legend>
        <ProductTypes
          class="multiselect--big"
          v-on:input="setProductType"
          :showLabel="false">
        </ProductTypes>
      </fieldset>
      <b-container fluid class="search__toolbar">
        <b-row align-h="end">
          <b-button v-if="enableResetButton" type="reset" variant="link">Reset</b-button>
          <b-button type="submit" variant="primary">Submit</b-button>
        </b-row>
      </b-container>
    </b-form>
  </section>
</template>

<script>
import { mapMutations } from 'vuex'
import FormsMixin from '../mixins/forms'
import ProductTypes from './Selects/ProductTypes'
import Countries from './Selects/Countries'

export default {
  name: 'TheCertificationBodiesSearch',
  data () {
    return {
      filter: {
        name: '',
        registrationNumber: ''
      }
    }
  },
  mixins: [FormsMixin],
  components: { ProductTypes, Countries },
  methods: {
    ...mapMutations('common/certificationBodies', ['setFilters']),
    ...mapMutations('common/search', ['SET_SEARCH_VALUES']),
    /**
     * @param value
     *
     * @return {void}
     */
    setProductType (value) {
      this.filter.product_type = value
    },
    /**
     * @param value
     *
     * @return {void}
     */
    setCountry (value) {
      this.filter.country = value
    },
    /**
     * @return {void}
     */
    search () {
      this.setFilters({})
      this.SET_SEARCH_VALUES(this.filter)

      this.$router.push({ path: '/certificationBodies/1/list' })
    }
  },
  computed: {
    /**
     *
     * @return {boolean}
     */
    enableResetButton () {
      return Object.values(this.filter).filter(value => value !== '' && value !== null).length > 0
    }
  }
}
</script>
