const profile = {
  title: 'Create user',
  form: {
    name: 'Name',
    email: 'Email',
    password: 'Password',
    rights: 'User rights'
  }
}

export default profile
