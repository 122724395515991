const modals = {
  error: {
    imports: {
      title: 'Certificates import',
      text: `Have you read the "Before you get started" section?`
    }
  },
  user: {
    title: 'User deletion',
    text: 'Are you sure you want to delete this user?'
  }
}

export default modals
