const notifications = {
  error: {
    title: 'Sorry, something went wrong!',
    '400': 'Request failed',
    '403': 'Access forbidden',
    '422': 'We need more information before we can continue.',
    '500': 'Our Server is not quite working!'
  },
  success: {
    title: 'Job successful!',
    '200': 'Success.',
    '201': 'Success.'
  },
  validator: {
    title: 'Form empty!',
    text: 'Please fill out at least one field.'
  },
  login: {
    title: 'Login failed.',
    text: 'Incorrect username or password.'
  },
  logger: {
    warning: 'The excel sheet is missing crucial information. Some certificates have not been imported.',
    error: 'None certificates have been imported.'
  },
  generic: {
    titles: {
      certificates_empty: 'Validation error.'
    },
    content: {
      certificates_empty: 'Please select one or more certificates.'
    }
  }
}

export default notifications
