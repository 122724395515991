<template>
  <b-container class="the-detail specifications">
    <form
      @submit.stop.prevent="create"
      v-if="certificate['product_type_id']">
      <b-row
        class="detailsection detailsection--productcategory"
        v-for="(item, index) in specifications"
        :key="index">
        <SpecificationFields
          :productTypeId="certificate['product_type_id']"
          :item="item"
          v-model="certificate.specifications[item['field_name']]" />
      </b-row>
      <b-row align-h="between">
        <b-col cols="auto">
          <b-button
            @click="$router.go(-1)"
            cols="auto"
            variant="outline-primary">
            Back
          </b-button>
        </b-col>
        <b-col cols="auto">
          <b-button
            cols="auto"
            variant="success"
            type="submit">
            Create
          </b-button>
        </b-col>
      </b-row>
    </form>
  </b-container>
</template>

<script>
import { toaster } from '../services/toast'
import { mapGetters } from 'vuex'
import SpecificationFields from './SpecificationFields'
import jsonToFormData from 'json-form-data'

export default {
  name: 'TheDetailSpecifications',
  data () {
    return {
      certificate: {
        specifications: {}
      }
    }
  },
  mounted () {
    this.$api.get('certificates', { id: this.certificateId }).then(({ data }) => {
      this.certificate = data
      this.$set(this.certificate, 'specifications', {})
    })
  },
  props: {
    certificateId: {
      type: Number
    }
  },
  components: { SpecificationFields },
  computed: {
    ...mapGetters('certificates/specifications', ['specifications'])
  },
  methods: {
    /**
     * @param event
     * @param name
     *
     * @return {void}
     */
    setSpecifications (event, name) {
      this.certificate.specifications[name] = event
    },
    /**
     *
     * @return {Promise<void>}
     */
    async create () {
      const initToaster = toaster(this['_bv__toast'])
      const isValid = await this.$validator.validateAll()

      if (isValid === false) return

      try {
        await this.$api.create(`certificates/${this.certificate.id}`, jsonToFormData({
          specifications: this.certificate['specifications']
        }))
        await this.$api.create('lookup/refresh/certificates')

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))

        this.$router.push(`/me/certificates/1`)
      } catch ({ data }) {
        const toaster = initToaster(data.message)

        toaster(this.$t('notifications.error.title'), this.$t(`notifications.error.${data.status_code}`))
      }
    }
  }
}
</script>
