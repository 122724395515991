<template>
  <multiselect
    v-if="laboratoriesLoaded === true"
    :placeholder="$t('common.selects.laboratory')"
    track-by="id"
    label="name"
    :custom-label="laboratoriesWithLocation"
    id="laboratory_id"
    :options="laboratories.data"
    v-model="internalState">
  </multiselect>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Laboratories',
  props: {
    showLabel: {
      type: Boolean,
      default: true
    },
    value: {
      type: Number,
      default: 0
    }
  },
  mounted () {
    this.internalState = { id: this.value }

    this.fetch({ limit: 500 }).then(() => {
      if ('data' in this.laboratories && this.laboratories.data.length > 0) {
        const laboratory = this.laboratories.data.filter(({ id }) => id === this.value) || null

        this.internalState = laboratory ? laboratory[0] : null
      }
    })
  },
  data () {
    return {
      internalState: null
    }
  },
  components: { Multiselect },
  computed: {
    ...mapGetters('common/laboratories', ['laboratories']),
    /**
     * @return {Boolean}
     */
    laboratoriesLoaded () {
      return this.laboratories.hasOwnProperty('data')
    }
  },
  watch: {
    /**
     * @param value
     */
    internalState (value) {
      if (value === null || typeof value === 'undefined') {
        this.$emit('input', null)

        return
      }

      this.$emit('input', value.id)
    },

    /**
     * @param value
     */
    laboratories (value) {
      if (!this.value) return

      const laboratory = value.data.filter(({ id }) => id === this.value) || null

      this.internalState = laboratory ? laboratory[0] : null
    },
    /**
     * @return {void}
     */
    value () {
      if (typeof this.laboratories.data === 'undefined') return

      const laboratory = this.laboratories.data.filter(({ id }) => id === this.value) || null

      this.internalState = laboratory ? laboratory[0] : null
    }
  },
  methods: {
    ...mapActions('common/laboratories', ['fetch']),
    laboratoriesWithLocation ({ name, location }) {
      const separator = location ? `, ${location}` : ''

      return `${name}${separator}`
    }
  }
}
</script>
