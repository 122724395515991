<template>
  <b-container class="the-detail">
    <b-form @submit.stop.prevent="create">
      <b-row>
        <h1 class="pageheading">{{ $t('productTypes.titles.create') }}</h1>
      </b-row>
      <CRUDToolbar
        :clickFn="create"
        :hasBackButton="false">
      </CRUDToolbar>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('productTypes.labels.productType') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              v-validate="{ required: true }"
              :class="{'validation--error': veeErrors.has('type') }"
              name="type"
              id="type"
              v-model="formInputs.type">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <CRUDToolbar :clickFn="create"></CRUDToolbar>
    </b-form>
  </b-container>
</template>

<script>
import CRUDToolbar from '../components/CRUDToolbar'
import { toaster } from '../services/toast'
import { mapActions } from 'vuex'
export default {
  name: 'TheProductTypesCreate',
  components: { CRUDToolbar },
  data () {
    return {
      formInputs: {
        type: ''
      }
    }
  },
  methods: {
    ...mapActions('common/productTypes', ['fetch']),
    /**
     *
     * @return {void}
     */
    async create () {
      const initToaster = toaster(this['_bv__toast'])
      const isValid = await this.$validator.validateAll()

      if (isValid === false) return

      try {
        await this.$api.create('productTypes', Object.deleteEmpty(this.formInputs))

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))

        await this.fetch({ limit: 500 })

        this.$router.push('/productTypes/1/list')
      } catch ({ data }) {
        const toaster = initToaster(data.message)

        toaster(this.$t('notifications.error.title'), this.$t(`notifications.error.${data.status_code}`))
      }
    }
  }
}
</script>
