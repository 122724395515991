<script>
import { toaster } from '../services/toast'
import { BButton } from 'bootstrap-vue'
import { mapMutations } from 'vuex'

export default {
  name: 'CertificateExport',
  /**
   * @param h
   */
  render (h) {
    return h(this.tag === 'button' ? BButton : this.tag,
      {
        attrs: {
          href: this.tag === 'a' ? '#' : null,
          variant: 'outline-primary'
        },
        on: {
          click: this.exportCertificates
        }
      },
      [this.text]
    )
  },
  props: {
    certificates: {
      type: Array
    },
    tag: {
      type: String
    },
    text: {
      type: String
    }
  },
  methods: {
    ...mapMutations('common/loadingSpinner', ['START_LOADING', 'END_LOADING']),
    /**
     *
     * @return {Promise<void>}
     */
    async exportCertificates () {
      const initToaster = toaster(this['_bv__toast'])
      let response

      if (!this.certificates.length) {
        initToaster(404)(
          this.$t('notifications.generic.titles.certificates_empty'),
          this.$t(`notifications.generic.content.certificates_empty`)
        )

        return
      }

      this.START_LOADING()

      try {
        response = await this.$api.create('export', {
          ids: this.certificates
        })

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))
      } catch ({ data }) {
        this.END_LOADING()

        const toaster = initToaster(data.status_code)

        toaster(this.$t('notifications.error.title'), data.message)

        return
      }

      this.END_LOADING()

      window.open(response)
    }
  }
}
</script>
