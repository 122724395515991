<template>
  <b-container fluid class="the-results">
    <b-row align-h="between">
      <h1 class="pageheading">{{ $t('productTypes.titles.list') }}</h1>
      <b-col cols="auto">
        <b-button
          variant="success"
          @click="createProductTypes">
          {{ $t('myCertificates.toolbar.new') }}
        </b-button>
      </b-col>
    </b-row>
    <b-row class="the-results__filterbar">
      <b-form inline>
        <b-form-input
          v-model="filter.type"
          :placeholder='$t("productTypes.labels.productType")'>
        </b-form-input>
      </b-form>
      <div class="bubble">
        <span> {{ totalPages }} {{ $t('common.hits') }} </span>
      </div>
    </b-row>
    <b-row class="the-results__list">
      <b-table
        selectable
        select-mode="single"
        @row-selected="updateProductTypes"
        striped
        borderless
        hover
        @sort-changed="sortingChanged"
        :no-local-sorting="true"
        stacked="sm"
        :items="items"
        :fields="fields"
        id="resultlist">
      </b-table>
    </b-row>
    <b-row class="the-results__toolbar">
      <b-container fluid class="toolbar">
        <b-row align-h="center">
          <b-col>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPages"
              :per-page="perPage"
              @input="fetchNextPage">
            </b-pagination>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
    <b-row align-h="between">
      <b-col cols="auto"></b-col>
      <b-col cols="auto">
        <b-button
          @click="createProductTypes"
          variant="success">
          {{ $t('myCertificates.toolbar.new') }}
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { applyRule } from '../services/request'
export default {
  name: 'TheProductTypesList',
  data () {
    return {
      totalPages: 1,
      perPage: 25,
      filter: {
        type: ''
      },
      sorting: {
        sortBy: '',
        orderBy: ''
      },
      currentPage: 1,
      fields: [
        { key: 'select', label: '' },
        { key: 'type', label: this.$t('productTypes.labels.productType'), sortable: true, class: 'productname' }
      ],
      items: []
    }
  },
  mounted () {
    this.fetch({ page: this.currentPage })
  },
  computed: {
    ...mapGetters('common/productTypes', ['types'])
  },
  watch: {
    /**
     * @param data
     * @param meta
     *
     * @return {void}
     */
    types ({ data, meta }) {
      const { pagination } = meta

      this.items = data
      this.totalPages = pagination.total
      this.perPage = pagination.per_page
    },
    filter: {
      /**
       *
       * @param inputs
       *
       * @return {void}
       */
      handler: function (inputs) {
        let query = { search: 1, ...inputs }

        applyRule(query, 'length:3')

        this.fetch({
          query: Object.keys(Object.deleteEmpty(query)).length === 1 ? {} : query
        })
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('common/productTypes', ['fetch']),
    /**
     *
     * @return {Promise}
     */
    async sortingChanged ({ sortBy, sortDesc }) {
      this.sorting.orderBy = sortDesc ? 'desc' : 'asc'
      this.sorting.sortBy = sortBy

      let query = { search: 1, ...this.filter }

      applyRule(query, 'length:3')

      await this.fetch({
        ...this.sorting,
        query: Object.keys(Object.deleteEmpty(query)).length === 1 ? {} : query
      })
    },
    /**
     *
     * @return {Promise}
     */
    async fetchNextPage () {
      const sorting = this.sorting.orderBy && this.sorting.sortBy ? this.sorting : null

      await this.fetch({
        page: this.currentPage,
        query: Object.keys(Object.deleteEmpty(this.filter)).length === 1 ? {} : this.filter,
        ...sorting
      })
    },
    /**
     *
     * @return {void}
     */
    updateProductTypes (items) {
      this.$router.push(`/productTypes/${items[0].id}/update`)
    },
    /**
     *
     * @return {void}
     */
    createProductTypes () {
      this.$router.push({ path: '/productTypes' })
    },
    /**
     * @returns {void}
     */
    checkAll (state) {
      this.selectedProductTypes = state ? this.items.map(item => item.id) : []
    }
  }
}
</script>
