export default {
  methods: {
    /**
     * @param hits
     *
     * @returns {Array}
     */
    lookupProvider ({ hits }) {
      let items = []

      if (typeof hits !== 'object') {
        return []
      }

      for (let hit of hits) {
        let { _source } = hit

        items.push({
          isActive: true,
          id: _source.id,
          product_name: _source.product_name,
          product_type: _source.productType.type,
          owner_name: _source.owner.name,
          certificate_nr: _source.certificate_nr,
          validity_until: _source.validity_until
        })
      }

      return items || []
    }
  }
}
