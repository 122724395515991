<template>
  <multiselect
    :placeholder="$t('common.selects.standard')"
    track-by="standard"
    label="standard"
    id="standard_id"
    :options="options"
    v-model="internalState">
  </multiselect>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Standards',
  props: {
    productTypeId: {
      type: Number
    },
    value: {
      type: Number
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  methods: {
    ...mapActions('common/standards', ['fetch'])
  },
  data () {
    return {
      internalState: null
    }
  },
  components: { Multiselect },
  computed: {
    ...mapGetters('common/standards', ['standards']),
    options: function () {
      if (!('data' in this.standards)) {
        return []
      }

      // edge-case when all standards have to be displayed (search for instance)
      if (!this.value && !this.productTypeId && 'data' in this.standards) {
        return this.standards.data
      }

      return this.standards.data.filter(item => item.product_type_id === this.productTypeId)
    }
  },
  watch: {
    value: function (value) {
      const standard = this.standards.data.filter(item => item.id === value) || null

      this.internalState = standard ? standard[0] : null
    },
    internalState: function (value) {
      if (!value) {
        this.$emit('input', null)

        return
      }

      this.$emit('input', value.id)
    }
  },
  created: function () {
    this.fetch({ limit: 500 })
  }
}
</script>
