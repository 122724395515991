/**
 *
 * @param target
 *
 * @return {{}}
 */
Object.deleteEmpty = target => {
  const o = { ...target }

  for (let prop in o) {
    if (!o.hasOwnProperty(prop) || o[prop]) continue

    Reflect.deleteProperty(o, prop)
  }

  return o
}
