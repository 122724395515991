<template>
  <div>
    <b-form-group>
      <b-form-radio-group v-model="selected">
        <b-form-radio
          v-model="selected"
          name="file-upload"
          value="logo">
          {{ $t('common.browse') }}
        </b-form-radio>
        <b-form-radio
          v-model="selected"
          name="file-upload"
          value="logo_url">
          {{ $t('common.url') }}
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>

    <b-form-input
      :value="preSelectValue"
      placeholder="Enter a url..."
      v-if="selected === 'logo_url'"
      @change="setFile">
    </b-form-input>
    <b-form-file
      id="file"
      v-if="selected === 'logo'"
      class="file--md"
      accept="image/png, image/jpeg"
      @change="setFile"
      placeholder="Choose a file..."
      drop-placeholder="Drop file here...">
    </b-form-file>
  </div>
</template>

<script>
export default {
  name: 'FileInput',
  data () {
    return {
      selected: 'logo'
    }
  },
  props: {
    preSelect: {
      type: String
    },
    preSelectValue: {
      type: String
    }
  },
  watch: {
    preSelect (value) {
      this.selected = value
    }
  },
  methods: {
    /**
     *
     * @param event
     *
     * @return {void}
     */
    setFile (event) {
      let value = ''

      if (this.selected === 'logo') {
        value = event.target.files[0]
      }

      if (this.selected === 'logo_url') {
        value = event
      }

      this.$emit('setFile', { value, logoType: this.selected })
    }
  }
}
</script>
