/**
 * Removes time spec from date time string
 *
 * @param date
 *
 * @returns {T[]}
 */
export const timeRemoval = date => date.slice(0, 10)

/**
 * Strip time from date stirng
 *
 * @param date
 *
 * @returns {string}
 */
export const humanDate = date => {
  if (typeof date === 'undefined') {
    return ''
  }

  return date.length > 10 ? timeRemoval(date) : date
}
