<template>
  <b-row align-h="between">
    <b-col cols="auto">
      <b-button
        v-if="hasBackButton"
        variant="outline-primary"
        @click="$router.go(-1)">
        {{ $t('common.back') }}
      </b-button>
    </b-col>
    <b-col cols="auto">
      <b-button
        type="submit"
        variant="success">
        {{ getLocalByFunctionName }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: 'CRUDToolbar',
  props: {
    clickFn: {
      type: Function
    },
    hasBackButton: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    /**
     *
     * @return {TranslateResult|*}
     */
    getLocalByFunctionName () {
      return this.clickFn.name === 'bound create' ? this.$t('common.create') : this.$t('common.update')
    }
  }
}
</script>
