<template>
  <b-container class="the-detail">
    <b-row class="detailsection detailsection--head">
      <span class="detailsection__heading">{{ $t('results.list.registration_number') }}</span>
      <b-row
        align-h="between"
        class="detailsection__content">
        <b-col cols="auto">
          <h1>{{ baseInfo.certificate_nr }}</h1>
        </b-col>
        <b-col cols="auto">
          <CertificateExport
            tag="a"
            :certificates="[baseInfo.id]"
            :text="$tc('myCertificates.toolbar.export', 0)"/>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="detailsection detailsection--manufacturer">
      <h3 class="detailsection__heading">{{ $t('detail.headings.manufacturer') }}</h3>
      <b-row class="detailsection__content">
        <b-col col cols="12" md="12">
          <b-row align-v="start">
            <b-col
                    align-self="start"
                    cols="12"
                    order-md="12"
                    md="3"
                    v-if="typeof owner.logo !== 'undefined' || typeof owner.logo_url !== 'undefined'">
              <img
                      class="logo"
                      :src="logoURL"/>
            </b-col>
            <b-col
              cols="12"
              md="4"
              class="manufacturerinfo__address">
              <strong>{{ owner.name }}</strong> <br />
              <span v-if="owner.street !== null">
                {{ owner.street }} <br />
              </span>
              <span v-if="owner.zip !== null || owner.location !== null">
                {{ owner.zip }} {{ owner.location }}  <br />
              </span>
              <span v-if="owner.country_id !== null">
                {{ getCountryNameById }}
              </span>
            </b-col>
            <b-col
              cols="12"
              md="3"
              offset-md="2"
              class="manufacturerinfo__contact">
              <dl>
                <div v-if="owner.phone !== null">
                  <dt>{{ $t('common.phone') }}</dt>
                  <dd>{{ owner.phone }}</dd>
                </div>
                <div v-if="owner.fax !== null">
                  <dt>{{ $t('common.fax') }}</dt>
                  <dd>{{ owner.fax }}</dd>
                </div>
                <div v-if="owner.email !== null">
                  <dt>{{ $t('common.mail') }}</dt>
                  <dd>
                    <a :href="`mailto:${owner.email}`">
                      {{ owner.email }}
                    </a>
                  </dd>
                </div>
                <div v-if="owner.website !== null">
                  <dt>{{ $t('common.web') }}</dt>
                  <dd>
                    <a
                      target="_blank"
                      :href="owner.website">
                      {{ owner.website }}
                    </a>
                  </dd>
                </div>
              </dl>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="detailsection detailsection--productcategory">
      <h3 class="detailsection__heading">{{ $t('detail.headings.product_category') }}</h3>
      <b-row class="detailsection__content">
        <b-col>
          {{ productType.type }}
        </b-col>
      </b-row>
    </b-row>
    <b-row class="detailsection detailsection--productname">
      <span class="detailsection__heading">{{ $t('detail.headings.product_name') }}</span>
      <b-row class="detailsection__content">
        <b-col>
          {{ baseInfo.product_name }}
        </b-col>
      </b-row>
    </b-row>
    <b-row class="detailsection detailsection--testingbase">
      <h3 class="detailsection__heading">{{ $t('detail.headings.testing_base') }}</h3>
      <b-row class="detailsection__content">
        <b-col>
          <p>
            {{ standard.standard }}
          </p>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="detailsection detailsection--conformitysign">
      <h3 class="detailsection__heading">{{ $t('detail.headings.conformity_sign') }}</h3>
      <b-row class="detailsection__content">
        <b-col v-if=" 'conformitySign' in baseInfo">
          <img :src="baseInfo.conformitySign.data.sign_url"/>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="detailsection detailsection--registrationnumber">
      <h3 class="detailsection__heading">{{ $t('results.list.registration_number') }}</h3>
      <b-row class="detailsection__content">
        <b-col>
          {{ baseInfo.certificate_nr }}
        </b-col>
      </b-row>
    </b-row>
    <b-row class="detailsection detailsection-expirationdate">
      <h3 class="detailsection__heading">{{ $t('detail.headings.expiration_date') }}</h3>
      <b-row class="detailsection__content">
        <b-col>
          {{ baseInfo.validity_until | humanDate }}
        </b-col>
      </b-row>
    </b-row>
    <b-row class="detailsection detailsection--certificationBody">
      <h3 class="detailsection__heading">{{ $t('results.list.certification_body') }}</h3>
      <b-row class="detailsection__content">
        <b-col>
          {{ certificationBody.name }}
        </b-col>
      </b-row>
    </b-row>
    <b-row
      class="detailsection detailsection--technicaldata"
      v-if="baseInfo.specifications !== null && specifications.length">
      <h3 class="detailsection__heading">{{ $t('detail.headings.technical_data') }}</h3>
      <b-row class="detailsection__content specifications__container">
        <b-col
          cols="6"
          lg="4"
          :key="index"
          v-for="(item, index) in specifications">
          <span class="specifications__label">
            {{ item['label'] }}
          </span>
          <span class="specifications__value">
            {{ baseInfo.specifications[item['field_name']] }}
          </span>
        </b-col>
      </b-row>
    </b-row>
    <b-row
      class="detailsection detailsection--document"
      v-if="baseInfo['data_sheet']">
      <h3 class="detailsection__heading">{{ $t('detail.headings.document') }}</h3>
      <b-row class="detailsection__content">
        <b-col>
          <a
            target="_blank"
            :href="baseInfo['data_sheet']">
            <span>{{ $t('detail.text.document') }}</span>
          </a>
        </b-col>
      </b-row>
    </b-row>
    <b-row
      class="detailsection detailsection--document"
      v-if="baseInfo['data_sheet_link']">
      <h3 class="detailsection__heading">{{ $t('detail.headings.data_sheet_link') }}</h3>
      <b-row class="detailsection__content">
        <b-col>
          <a
            target="_blank"
            :href="baseInfo['data_sheet_link']">
            <span>{{ $t('detail.text.document_link') }}</span>
          </a>
        </b-col>
      </b-row>
    </b-row>
    <b-row
      class="detailsection detailsection--document"
      v-if="baseInfo['general_technical_data']">
      <h3 class="detailsection__heading">{{ $t('detail.headings.general_technical_data') }}</h3>
      <b-row class="detailsection__content">
        <b-col>
          <span v-html="$options.filters.nl2br(baseInfo['general_technical_data'])"></span>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="detailsection detailsection--additional">
      <h3 class="detailsection__heading">{{ $t('detail.headings.additional_information') }}</h3>
      <b-row class="detailsection__content">
        <b-col>
          <b-button
            @click="getCertificatesByOwner"
            variant="link">
            View all Certificates by {{ owner.name }}
          </b-button>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="the-detail__toolbar">
      <b-container fluid class="toolbar">
        <b-row align-h="between">
          <b-col>
            <b-button
              @click="goBack()"
              variant="outline-primary">
              {{ $t('detail.toolbar.to_results') }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { timeRemoval } from '../services/date'
import CertificateExport from '../components/CertificateExport'

export default {
  name: 'TheCertificatesDetail',
  components: { CertificateExport },
  data () {
    return {
      baseInfo: {},
      owner: {},
      productType: {},
      certificationBody: {},
      standard: {},
      specifications: [],
      from: ''
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vue => {
      vue.from = from.path
    })
  },
  /**
   *
   *
   * @return {void}
   */
  mounted () {
    const { id } = this.$route.params

    this.fetch({ id, include: 'certificationBody,owner,productType,standard,conformitySign' })
      .then(() => {
        if ('data' in this.details) {
          const { data } = this.details

          this.baseInfo = data
          this.owner = data.owner.data
          this.productType = data.productType.data
          this.certificationBody = data.certificationBody.data
          this.standard = data.standard.data

          if (this.baseInfo.hasOwnProperty('validity_until') && this.baseInfo.validity_until !== null) {
            this.baseInfo.validity_until = timeRemoval(this.baseInfo.validity_until)
          }
        }
      })

    this.fetchCountries({ limit: 500 })
  },
  computed: {
    ...mapGetters('certificates/certificateDetails', ['details']),
    ...mapGetters('common/productTypes', ['types']),
    ...mapGetters('common/standards', ['standards']),
    ...mapGetters('common/countries', ['countries']),
    ...mapGetters('common/certificationBodies', ['certificationBodies']),
    /**
     * @return {*}
     */
    getCountryNameById () {
      if (!('country_id' in this.owner) && !this.countries.length) return ''

      const countries = this.countries.filter(country => country.id === this.owner.country_id)

      if (!countries.length) return ''

      return countries[0].country
    },
    /**
     *
     * @return {*}
     */
    logoURL () {
      return this.owner.logo
        ? this.owner.logo
        : this.owner.logo_url
          ? this.owner.logo_url
          : null
    }
  },
  watch: {
    'baseInfo.specifications': function (value) {
      if (value === null) return

      const fields = Object.keys(value)

      this.$api.get('specifications', {
        params:
          {
            fields, product_type_id: this.baseInfo.product_type_id
          }
      }).then(data => {
        this.specifications = data.filter(item => item.field_name.toLowerCase() !== 'labeling')
      })
    }
  },
  methods: {
    ...mapActions('certificates/certificateDetails', ['fetch']),
    ...mapActions('common/countries', { fetchCountries: 'fetch' }),
    ...mapMutations('certificates/searchResults', ['SET_FIELDS']),
    /**
     *
     * @param data
     * @param textProp
     *
     * @return {Array}
     */
    options (data, textProp) {
      const opts = []

      for (let item of data) {
        opts.push({
          value: item.id,
          text: item[textProp]
        })
      }

      return opts
    },
    /**
     * @return {void}
     */
    goBack () {
      this.$router.push(`${this.from}?restore=true`)
    },
    /**
     *
     * @return {void}
     */
    getCertificatesByOwner () {
      this.SET_FIELDS({ owner_name: this.owner.name })

      this.$router.go(-1)
    }
  }
}
</script>
