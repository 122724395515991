export const SET_DETAILS = 'setCertificateDetails'
export const SET_CERTIFICATION_BODIES = 'setCertificationBodies'
export const SET_ME = 'setMe'
export const SET_OWNERS = 'setOwners'
export const SET_PRODUCT_TYPES = 'setProductTypes'
export const SET_FILTERS = 'setFilters'
export const SET_CERTIFICATES = 'setCertificates'
export const SET_USERS = 'setUsers'
export const SET_STANDARDS = 'setStandards'
export const SET_TOKEN = 'setToken'
export const SET_COUNTRIES = 'setCountries'
export const SET_LABORATORIES = 'setLaboratories'
export const RESET_FILTER = 'resetFilter'
export const SET_SPECIFICATIONS = 'setSpecifications'
export const SET_TOTAL_PAGES = 'setTotalPages'
export const SET_TOTAL_CERTIFICATES = 'setTotalCertificates'
export const SET_SIGNS = 'setSigns'
export const SET_VISIBILITY = 'setVisibility'
export const RESET_ME = 'resetMe'
