<template>
  <b-container class="the-detail">
    <b-form @submit.stop.prevent="update">
      <CRUDToolbar
        :clickFn="update"
        :hasBackButton="false">
      </CRUDToolbar>
      <b-row class="detailsection detailsection--head">
        <span class="detailsection__heading">{{ $t('detail.headings.registration_number') }}</span>
        <b-row class="detailsection__content">
          <b-col>
            <h1>{{ baseInfo.certificate_nr }}</h1>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('detail.headings.product_category') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <ProductTypes
              class="multiselect--big"
              :limitChoices="limitChoices"
              :value="baseInfo['product_type_id']"
              v-on:input="setProductType">
            </ProductTypes>
          </b-col>
        </b-row>
      </b-row>
      <b-row
        v-if="isPowerUser"
        class="detailsection detailsection--certificationBody">
        <h3 class="detailsection__heading">{{ $t('detail.headings.certification_body') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <CertificationBodies
              :preselect="baseInfo['certification_body_id']"
              v-on:certificationBody="setCertificationBody"/>
          </b-col>
        </b-row>
      </b-row>
      <div v-if=" 'specifications' in baseInfo">
        <b-row
          class="detailsection detailsection--productcategory"
          v-for="(item, index) in specifications"
          :style="{ display: (!filterOnly || filterOnly && item['filter']) ? 'block' : 'none' }"
          :key="index">
          <h3 class="detailsection__heading">
            {{ item['label'] }}
          </h3>
          <b-row class="detailsection__content">
            <SpecificationFields
              :productTypeId="baseInfo.product_type_id"
              :item="item"
              v-model="baseInfo.specifications[item['field_name']]" />
          </b-row>
        </b-row>
      </div>
      <b-row class="detailsection detailsection--productname">
        <span class="detailsection__heading">{{ $t('detail.headings.product_name') }}</span>
        <b-row class="detailsection__content">
          <b-col>
            <b-input v-model="baseInfo.product_name"></b-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--testingbase">
        <h3 class="detailsection__heading">{{ $t('detail.headings.testing_base') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <p>
              <Standards
                :productTypeId="baseInfo.product_type_id"
                v-model="baseInfo['standard_id']"
                v-on:standard="setStandard">
              </Standards>
            </p>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--conformitysign">
        <h3 class="detailsection__heading">{{ $t('detail.headings.conformity_sign') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <ConformitySigns
              v-model="baseInfo.conformity_sign_id"
              :productTypeId="baseInfo.product_type_id" />
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--registrationnumber">
        <h3 class="detailsection__heading">{{ $t('detail.headings.registration_number') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-input v-model="baseInfo.certificate_nr"></b-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection-expirationdate">
        <h3 class="detailsection__heading">{{ $t('detail.headings.expiration_date') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-input
            type="date"
            v-model="baseInfo.validity_until">
            </b-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection-expirationdate">
        <h3 class="detailsection__heading">{{ $t('detail.headings.general_technical_data') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-textarea
              name="general_technical_data"
              type="text"
              v-model="baseInfo.general_technical_data">
            </b-form-textarea>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--owner">
        <h3 class="detailsection__heading">{{ $t('detail.multiSelect.owner.label') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <Owners
              v-on:owner="setOwner"
              :preselect="owner.id">
            </Owners>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--document">
        <h3 class="detailsection__heading">{{ $t('detail.headings.document') }}</h3>
        <b-row class="detailsection__content">
          <b-col
            cols="3"
            align-self="center"
            v-if="baseInfo['data_sheet']">
            <a
              target="_blank"
              :href="baseInfo['data_sheet']">
              <span>{{ $t('detail.text.document') }}</span>
            </a>
          </b-col>
          <b-col :cols="baseInfo['data_sheet'] ? 9 : 12">
            <b-form-file
              accept="application/pdf"
              v-model="baseInfo.data_sheet"
              placeholder="Choose a file..."
              drop-placeholder="Drop file here..."
            ></b-form-file>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection-expirationdate">
        <h3 class="detailsection__heading">{{ $t('detail.headings.data_sheet_link') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-input
              name="data_sheet_link"
              type="text"
              v-model="baseInfo.data_sheet_link">
            </b-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--additional">
        <h3 class="detailsection__heading">{{ $t('detail.headings.additional_information') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-button
              @click="getCertificatesByOwner"
              variant="link">
              View all Certificates by {{ owner.name }}
            </b-button>
          </b-col>
        </b-row>
      </b-row>
      <CRUDToolbar :clickFn="update"></CRUDToolbar>
    </b-form>
  </b-container>
</template>
<script>
import jsonToFormData from 'json-form-data'
import { timeRemoval } from '../services/date'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import CRUDToolbar from '../components/CRUDToolbar'
import { toaster } from '../services/toast'
import ProductTypes from '../components/Selects/ProductTypes'
import Standards from '../components/Selects/Standards'
import Owners from '../components/Selects/Owners'
import SpecificationFields from '../components/SpecificationFields'
import CertificationBodies from '../components/Selects/CertificationBodies'
import ConformitySigns from '../components/ConformitySigns'

export default {
  name: 'TheCertificatesUpdate',
  data () {
    return {
      baseInfo: {
        conformitySign: { data: { id: null } }
      },
      productType: {},
      certificationBody: {},
      standard: {},
      owner: {},
      limitChoices: [],
      filterOnly: false,
      specNames: ''
    }
  },
  components: {
    CertificationBodies,
    SpecificationFields,
    CRUDToolbar,
    Owners,
    ProductTypes,
    Standards,
    ConformitySigns
  },
  computed: {
    ...mapGetters('certificates/certificateDetails', ['details']),
    ...mapGetters('certificates/specifications', ['specifications']),
    ...mapGetters('users/me', ['me', 'isPowerUser'])
  },
  /**
   * @return {void}
   */
  mounted () {
    const { id } = this.$route.params

    this.fetch({ id, include: 'certificationBody,owner,productType,standard,conformitySign' })
      .then(() => {
        if ('data' in this.details) {
          const { data } = this.details

          this.baseInfo = data
          this.productType = data.productType.data
          this.certificationBody = data.certificationBody.data
          this.owner = data.owner.data
          this.standard = data.standard.data

          if (!('specifications' in this.baseInfo)) {
            this.baseInfo['specifications'] = {}
          }

          if (this.baseInfo.hasOwnProperty('validity_until') && this.baseInfo.validity_until !== null) {
            this.baseInfo.validity_until = timeRemoval(this.baseInfo.validity_until)
          }

          if (this.baseInfo.hasOwnProperty('validity_from') && this.baseInfo.validity_from !== null) {
            this.baseInfo.validity_from = timeRemoval(this.baseInfo.validity_from)
          }
        }
      })

    this.$api.get(`types/certificationBodies/${this.me.id}`).then(value => {
      this.limitChoices = value
    })
  },
  methods: {
    ...mapMutations('certificates/searchResults', ['setFilters']),
    ...mapActions('certificates/certificateDetails', ['fetch']),
    ...mapActions('certificates/specifications', { fetchSpecifications: 'fetch' }),
    getCertificatesByOwner () {
      this.setFilters({ owner_name: this.owner.name })

      this.$router.push('/me/certificates/1')
    },
    setCertificationBody (value) {
      this.baseInfo.certification_body_id = value
    },
    /**
     *
     * @param name
     * @param value
     *
     * @return {void}
     */
    setSpecifications ({ name, value }) {
      this.baseInfo.specifications[name] = value
    },
    /**
     *
     * @param value
     *
     * @return {Promise<void>}
     */
    async setProductType (value) {
      if (value === null) return

      this.baseInfo.product_type_id = value

      await this.fetchSpecifications(this.baseInfo.product_type_id)
    },
    /**
     *
     * @param value
     *
     * @return {void}
     */
    setStandard (value) {
      this.baseInfo.standard_id = value
    },
    /**
     *
     * @param value
     *
     * @return {void}
     */
    setOwner (value) {
      this.baseInfo.owner_id = value
    },
    /**
     *
     * @return {Promise<void>}
     */
    async update () {
      const initToaster = toaster(this['_bv__toast'])

      if (this.baseInfo.data_sheet && this.baseInfo.data_sheet.includes('storage/data_sheets')) {
        Reflect.deleteProperty(this.baseInfo, 'data_sheet')
      }

      try {
        await this.$api.create(`certificates/${this.baseInfo.id}`, jsonToFormData(this.baseInfo))
        await this.$api.create('lookup/refresh/certificates')

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))

        this.$router.push(`/me/certificates/1`)
      } catch ({ data }) {
        const toaster = initToaster(data.message)

        toaster(this.$t('notifications.error.title'), this.$t(`notifications.error.${data.status_code}`))
      }
    }
  }
}
</script>
