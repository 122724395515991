<template>
  <div style="width: 100%">
    <b-row
      class="detailsection__content assignments"
      v-for="(assignment, index) in assignments"
      :key="index">
      <b-col cols="4">
        <ProductTypes
          class="multiselect--big"
          v-model="assignment.product_type_id"
        />
      </b-col>
      <b-col cols="4">
        <Laboratories
          v-model="assignment.laboratory_id"
        />
      </b-col>
      <b-col cols="4">
        <b-button
          v-if="(index + 1) === assignments.length"
          @click="addOne()"
          variant="light"
          class="button--add">
          <img src="../assets/images/streamline-icon-add-circle@24x24.svg" />
        </b-button>
        <b-button
          v-if="assignments.length > 1"
          @click="deleteOne(index)"
          variant="light"
          class="button--add">
          <img src="../assets/images/streamline-icon-subtract-circle@24x24.svg" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import Laboratories from '../components/Selects/Laboratories'
import ProductTypes from '../components/Selects/ProductTypes'

export default {
  name: 'Assignment',
  props: {
    preAssignment: {
      type: Array
    },
    value: {
      type: Array
    }
  },
  data () {
    return {
      assignments: [
        {
          id: null,
          laboratory_id: 0,
          product_type_id: 0
        }
      ]
    }
  },
  components: {
    Laboratories,
    ProductTypes
  },
  methods: {
    /**
     * @return {void}
     */
    addOne () {
      this.assignments.push({
        id: null,
        laboratory_id: 0,
        product_type_id: 0
      })
    },
    /**
     *
     * @param id
     *
     * @return {void}
     */
    deleteOne (id) {
      this.assignments = this.assignments.filter((item, index) => index !== id)
    }
  },
  watch: {
    assignments: {
      /**
       *
       * @param value
       *
       * @return {void}
       */
      handler: function (value) {
        this.$emit('input', value)
      },
      deep: true
    },
    /**
     *
     * @param value
     *
     * @return {void}
     */
    value (value) {
      this.assignments = this.value
    }
  }
}
</script>
