import Vue from 'vue'
import Vuex from 'vuex'

import searchResults from './searchResults.module'
import certificateDetails from './certificateDetails.module'
import productTypes from './productTypes.module'
import standards from './standards.module'
import me from './me.module'
import auth from './auth.module'
import certificatesLookup from './elastic/lookups/certificatesLookup.module'
import certificationBodies from './certificationBodies.module'
import owners from './owners.module'
import users from './users.module'
import laboratories from './laboratories.module'
import countries from './countries.module'
import loadingSpinner from './loadingSpinner.module'
import specifications from './specifications.module'
import conformitySigns from './conformitySigns.module'
import search from './search.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    users: {
      namespaced: true,
      modules: {
        me,
        auth,
        users
      }
    },
    common: {
      namespaced: true,
      modules: {
        productTypes,
        standards,
        certificationBodies,
        countries,
        laboratories,
        loadingSpinner,
        search
      }
    },
    certificates: {
      namespaced: true,
      modules: {
        searchResults,
        certificateDetails,
        owners,
        specifications,
        conformitySigns
      }
    },
    lookups: {
      namespaced: true,
      modules: {
        certificatesLookup
      }
    }
  }
})
