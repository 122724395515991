<template>
  <b-row align-h="end">
    <b-col
      cols="auto"
      class="p-1">
      <b-button
        @click="importView"
        variant="outline-primary">
        {{ $t('myCertificates.toolbar.import') }}
      </b-button>

    </b-col>
    <b-col
      cols="auto"
      class="mr-auto p-1">
      <b-button
        variant="success"
        @click="certificateView">
        {{ $t('myCertificates.toolbar.new') }}
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import { mapMutations } from 'vuex'
import { toaster } from '../services/toast'
import axios from 'axios'
import env from '../../env'

export default {
  name: 'TheCertificatesCRUDToolbar',
  props: {
    productType: {
      type: Number
    },
    certificates: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    ...mapMutations('common/loadingSpinner', ['START_LOADING', 'END_LOADING']),
    /**
     * @return {void}
     */
    certificateView () {
      this.$router.push({ path: '/certificates' })
    },

    /**
     *
     * @return {void}
     */
    importView () {
      this.$router.push(`/me/import`)
    },

    /**
     *
     * @return {Promise<void>}
     */
    async exportCertificatesCsv () {
      this.START_LOADING()

      const { data } = await axios.post(env.APP_URL + '/api/export/certificates', {
        'ids': this.certificates,
        'product_type': this.productType
      })

      this.END_LOADING()

      var a = document.getElementById('csvExport')

      a.href = 'data:text/plain,' + encodeURIComponent(data)
      a.click()
    },
    /**
     *
     * @return {Promise<void>}
     */
    async exportCertificates () {
      const initToaster = toaster(this['_bv__toast'])
      let response

      if (!this.certificates.length) {
        initToaster(404)(
          this.$t('notifications.generic.titles.certificates_empty'),
          this.$t(`notifications.generic.content.certificates_empty`)
        )

        return
      }

      this.START_LOADING()

      try {
        response = await this.$api.create('export', {
          ids: this.certificates
        })

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))
      } catch ({ data }) {
        this.END_LOADING()

        const toaster = initToaster(data.status_code)

        toaster(this.$t('notifications.error.title'), data.message)

        return
      }

      this.END_LOADING()

      window.open(response)
    }
  }
}
</script>
