import { SET_TOKEN } from './mutationTypes'
import env from '../../env'

const state = {
  token: sessionStorage.getItem(env.ID_TOKEN)
}

const getters = {
  /**
   *
   * @param state
   * @return {string|null}
   */
  token (state) {
    return state.token
  }
}

const mutations = {
  /**
   *
   * @param state
   * @param token
   */
  [SET_TOKEN] (state, token) {
    if (token === null) {
      sessionStorage.removeItem(env.ID_TOKEN)
    }

    if (token !== null) {
      sessionStorage.setItem(env.ID_TOKEN, token)
    }

    state.token = sessionStorage.getItem(env.ID_TOKEN)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
