<template>
  <section class="product_types router_view_container">
    <router-view></router-view>
  </section>
</template>

<script>
export default {
  name: 'TheProductTypes'
}
</script>
