import Api from '../services/api'
import { SET_STANDARDS } from './mutationTypes'

const state = {
  /**
   * @type {{}}
   */
  standards: {},
  /**
   * @type {Array}
   */
  standardSelectItems: []
}

const getters = {
  /**
   *
   * @param state
   *
   * @returns {Array|getters.standards|(function(*))|*|{mutations, state, getters, actions, namespaced}}
   */
  standards (state) {
    return state.standards
  },
  /**
   *
   * @returns {Array}
   */
  standardItems (state, getters) {
    if (typeof getters.standards === 'object' && getters.standards.hasOwnProperty('data')) {
      for (let { standard } of getters.standards.data) {
        state.standardSelectItems.push(standard)
      }
    }

    return state.standardSelectItems
  }
}

const mutations = {
  /**
   *
   * @param state
   * @param standards
   *
   * @returns {void}
   */
  [SET_STANDARDS] (state, standards) {
    state.standards = standards
  }
}

const actions = {
  /**
   *
   * @param commit
   * @param params
   *
   * @returns {Promise<void>}
   */
  async fetch ({ commit } = {}, params) {
    const api = new Api()
    const { limit, page, query, sortBy, orderBy } = params

    commit('setStandards', await api.get('standards', {
      params: {
        limit,
        page,
        ...query,
        sortBy,
        orderBy
      }
    }))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
