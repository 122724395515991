const search = {
  title: 'CEN KEYMARK Database',
  navigation: {
    certificate_search: 'KEYMARK Certificates',
    certification_body_search: 'Certification Bodies'
  },
  form: {
    certificate_search: {
      heading: {
        product: 'Product',
        certificate: 'Certificate',
        holder: 'Certificate Holder'
      },
      label: {
        product_name: 'Product Name',
        product_category: 'Product type',
        certificate_number: 'Certificate Number',
        standard_reference: 'EN Standard reference',
        body_name: 'Certification Body',
        manufacturer: 'Company',
        manufacturer_country: 'Company Location'
      },
      placeholder: {
        product_name: 'Enter product name',
        product_category: 'Select product type',
        certificate_number: 'Enter certificate number',
        standard_reference: 'Select Reference',
        body_name: 'Enter Certification Body',
        manufacturer: 'Enter company',
        certificate_holder: 'Enter certificate holder',
        manufacturer_country: 'Enter Country',
        conformity_mark: 'Select Conformity Mark'
      }
    },
    certificationbody_search: {
      heading: {
        body: 'Certification Body',
        products: 'Products',
        location: 'Location'
      },
      label: {
        body_name: 'Certification Body Name',
        product_category: 'Product type'
      }
    }
  }
}

export default search
