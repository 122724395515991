/**
 * Main entry file for locales
 */

import en from './en/en.js'
import de from './de/de.js'

export default {
  en,
  de
}
