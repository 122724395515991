/**
 * Main entry file for the english translation.
 *
 * Please separate component-based translation into a single file;Which can then be imported and stored into the object.
 */
import search from './views/search'

const de = {
  search
}

export default de
