const standards = {
  titles: {
    create: 'Create standards',
    update: 'Update standards',
    list: 'Search results for standards'
  },
  standards: 'Standards',
  labels: {
    standard: 'Standard',
    standard_reference: 'Standard reference',
    products: 'Product type'
  }
}

export default standards
