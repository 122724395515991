<template>
    <router-link
      @click.native="logout"
      to="login">
        <span>{{ $t('navbar.items.logout') }}</span>
    </router-link>
</template>

<script>
import { mapMutations } from 'vuex'

export default {
  name: 'Logout',
  methods: {
    ...mapMutations('users/me', ['resetMe']),
    ...mapMutations('users/auth', ['setToken']),
    /**
    * @return {Promise<void>}
    */
    async logout () {
      this.resetMe()
      this.setToken(null)

      this.$router.push('/login')
    }
  }
}
</script>
