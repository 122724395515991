<template>
  <b-table
    selectable
    @sort-changed="sortingChanged"
    :no-local-sorting="true"
    select-mode="single"
    @row-selected="routeToView"
    striped
    borderless
    no-sort-reset
    hover
    stacked="sm"
    :items="items"
    :fields="fields">
    <template
      v-slot:cell(select)="data"
      v-if="(isAdmin || isPowerUser) && visibility === 'private'"
    >
      <b-form-checkbox
        :value="data.item.id"
        v-model="currentSelect"
      >
      </b-form-checkbox>
    </template>
  </b-table>
</template>

<script>
import { applyRule } from '../services/request'
import { _underscore } from '@suben/keys_transformer'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ListTable',
  data () {
    return {
      currentSelect: [],
      sorting: {
        orderBy: null,
        sortBy: null
      }
    }
  },
  props: {
    items: {
      type: Array
    },
    fields: {
      type: Array
    }
  },
  watch: {
    /**
     * @param value
     */
    currentSelect (value) {
      this.$emit('select', value)
    },
    /**
     * @return {void}
     */
    items () {
      this.currentSelect = []
    }
  },
  computed: {
    ...mapGetters('users/me', ['isPowerUser', 'isAdmin']),
    ...mapGetters('common/certificationBodies', ['visibility']),
    ...mapGetters('common/search', ['searchValues'])
  },
  methods: {
    ...mapActions('common/certificationBodies', ['fetch']),
    /**
     * @param sortBy
     * @param sortDesc
     */
    async sortingChanged ({ sortBy, sortDesc }) {
      this.sorting.orderBy = sortDesc ? 'desc' : 'asc'
      this.sorting.sortBy = sortBy

      sessionStorage.setItem('certification_bodies_sorting', JSON.stringify(this.sorting))

      this.$emit('sort', this.sorting)

      let query = Object.deleteEmpty({ search: 1, ...this.searchValues })

      applyRule(query, 'length:3')

      if (Object.keys(query).length === 1) {
        query = {}
      }

      await this.fetch({
        include: 'productTypes',
        query: Object.keys(query).length === 1 ? {} : _underscore(query),
        ...this.sorting
      })
    },
    /**
     *
     * @param item
     */
    routeToView ([item]) {
      switch (this.visibility) {
        case 'public':
          this.$router.push(`/certificationBodies/${item.id}/detail`)
          break
        case 'private':
          this.$router.push(`/certificationBodies/${item.id}/update`)
          break
      }
    }
  }
}
</script>
