<template>
  <b-container class="the-detail">
    <b-form @submit.stop.prevent="update">
      <b-row align-h="between">
        <h1 class="pageheading">{{ $t('owner.titles.update') }}</h1>
        <CRUDToolbar
          :clickFn="update"
          :hasBackButton="false">
        </CRUDToolbar>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.name') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="name"
              v-model="content.name">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.street') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="street"
              v-model="content.street">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.zip') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="zip"
              v-model="content.zip">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.location') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="location"
              v-model="content.location">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.country') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <Countries
              :preselect="content.country_id"
              v-on:country="setCountry"
              :showLabel="false">
            </Countries>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.phone') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="phone"
              v-model="content.phone">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.fax') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="fax"
              v-model="content.fax">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.email') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="email"
              v-model="content.email">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.website') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="website"
              v-model="content.website">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.numberOfProductionSites') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              v-validate="{ required: true }"
              :class="{'validation--error': veeErrors.has('numberOfProductionSites') }"
              type="number"
              name="numberOfProductionSites"
              id="numberOfProductionSites"
              v-model="content.number_of_production_sites">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.moreContacts') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
              id="moreContacts"
              v-model="content.more_contacts">
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--document">
        <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.logo') }}</h3>
        <b-row class="detailsection__content">
          <b-col
            cols="3"
            align-self="center"
            v-if="!showLogo">
            <img
              class="logo"
              :src="logoURL"/>
          </b-col>
          <b-col :cols="content.logo ? 9 : 12">
            <FileInput
              :preSelect="logoType()"
              :preSelectValue="this.content.logo_url ? this.content.logo_url : null"
              v-on:setFile="setFile" />
          </b-col>
        </b-row>
      </b-row>
      <CRUDToolbar :clickFn="update"></CRUDToolbar>
    </b-form>
  </b-container>
</template>

<script>
import CRUDToolbar from '../components/CRUDToolbar'
import Countries from '../components/Selects/Countries'
import { toaster } from '../services/toast'
import { mapActions } from 'vuex'
import FileInput from '../components/FileInput'
import jsonToFormData from 'json-form-data'

export default {
  name: 'TheOwnerUpdate',
  components: { CRUDToolbar, Countries, FileInput },
  data () {
    return {
      content: {}
    }
  },
  beforeRouteEnter (to, from, next) {
    const { id } = to.params

    if (!id) {
      next(false)
      return
    }

    next(async vue => {
      const { data } = await vue.$api.get(`owners/${id}`)

      vue.content = data
    })
  },
  computed: {
    logoURL () {
      return this.content.logo
        ? this.content.logo
        : this.content.logo_url
          ? this.content.logo_url
          : null
    },
    /**
     * @return {boolean}
     */
    showLogo () {
      const hasFile = this.content.logo && (this.content.logo instanceof File)

      return hasFile || this.content.logo_url
    }
  },
  methods: {
    ...mapActions('certificates/owners', ['fetch']),
    /**
     * @return {string|null}
     */
    logoType () {
      return this.content.logo
        ? 'logo'
        : this.content.logo_url
          ? 'logo_url'
          : null
    },
    /**
     *
     * @param value
     * @param logoType
     *
     * @return {void}
     */
    setFile ({ value, logoType }) {
      if (logoType === 'logo' && ('logo_url' in this.content)) {
        Reflect.deleteProperty(this.content, 'logo_url')
      }

      if (logoType === 'logo_url' && ('logo' in this.content)) {
        Reflect.deleteProperty(this.content, 'logo')
      }

      this.content[logoType] = value
    },
    /**
     *
     * @return {void}
     */
    async update () {
      const initToaster = toaster(this['_bv__toast'])

      let requestBody = {
        ...this.content
      }

      if (requestBody.logo instanceof File === false && requestBody.logo) {
        Reflect.deleteProperty(requestBody, 'logo')
      }

      try {
        await this.$api.create(`owners/${this.content.id}`, jsonToFormData(requestBody))

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))

        await this.fetch({ limit: 500 })

        this.$router.push('/owners/1/list')
      } catch ({ data }) {
        const toaster = initToaster(data.message)

        toaster(this.$t('notifications.error.title'), this.$t(`notifications.error.${data.status_code}`))
      }
    },
    /**
     * @param value
     *
     * @return {void}
     */
    setCountry (value) {
      this.content.country_id = value
    }
  }
}
</script>
