<template>
  <b-col
      cols="12">
    <multiselect
      :placeholder="item['label']"
      :value="value"
      v-on:input="$emit('input', $event)"
      :name="item['field_name']"
      :class="{'validation--error': veeErrors.has(item['field_name']) && !filterOnly }"
      v-if="(isSelect && filterOnly === false) || (isSelect && showFilterOnly)"
      v-validate="{ required: item.required === true && !filterOnly }"
      :options="item.options">
    </multiselect>
    <b-form-input
      :type="item['data_type']"
      :step="item['data_type'] === 'number' ? 'any' : ''"
      :placeholder="item['label']"
      :value="value"
      v-on:input="$emit('input', $event)"
      v-if="(isInput && filterOnly === false) || (isInput && showFilterOnly)"
      :class="{'validation--error': veeErrors.has(item['field_name']) && !filterOnly }"
      :name="item['field_name']"
      v-validate="{ required: item['required'] === true && !filterOnly }">
    </b-form-input>
  </b-col>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'Specifications',
  components: { Multiselect },
  data () {
    return {
      label: ''
    }
  },
  props: {
    productTypeId: {
      type: Number
    },
    item: {
      type: Object
    },
    value: {
      validator: function (value) {
        return typeof value === 'number' || typeof value === 'string'
      }
    },
    filterOnly: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    /**
     *
     * @return {boolean}
     */
    isInput () {
      return this.item['field_type'] === 'input'
    },
    /**
     *
     * @return {boolean}
     */
    isSelect () {
      return this.item['field_type'] === 'select'
    },
    /**
     *
     * @return {boolean}
     */
    showFilterOnly () {
      return this.filterOnly === true && this.item['filter'] === true
    }
  }
}
</script>
