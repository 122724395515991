<template>
  <b-row class="the-results__filterbar">
    <b-form>
      <ProductTypes
        class="multiselect--big"
        :value="this.listFilter.product_type"
        v-on:input="setProductType"
        :showLabel="false">
      </ProductTypes>
      <b-form-input
              v-model="listFilter.owner_name"
              :placeholder='$t("search.form.certificate_search.placeholder.certificate_holder")'>
      </b-form-input>
      <b-form-input
        v-model="listFilter.product_name"
        :placeholder='$t("search.form.certificate_search.placeholder.product_name")'>
      </b-form-input>
      <b-form-input
              v-model="listFilter.certificate_nr"
              :placeholder='$t("search.form.certificate_search.placeholder.certificate_number")'>
      </b-form-input>
      <CertificationBodies
        :preselect="this.listFilter.certification_body_id"
        v-on:certificationBody="setCertificationBody"/>
      <b-row class="specifications__filter">
        <TheSpecificationsCollapse :hasProductType="listFilter.product_type !== null && items.length > 0">
          <template>
            <div
              :key="index"
              :style="{ display: withFilter && item['filter'] ? 'flex' : 'none' }"
              v-for="(item, index) in items">
              <SpecificationFields
                :productTypeId="listFilter.product_type"
                :filterOnly="withFilter"
                :item="item"
                v-model="listFilter.specifications[item['field_name']]" />
            </div>
          </template>
        </TheSpecificationsCollapse>
      </b-row>
    </b-form>
  </b-row>
</template>

<script>
import { mapGetters } from 'vuex'
import SpecificationFields from './SpecificationFields'
import ProductTypes from './Selects/ProductTypes'
import TheSpecificationsCollapse from './TheSpecificationsCollapse'
import CertificationBodies from './Selects/CertificationBodies'

export default {
  name: 'TheCertificatesListFilter',
  components: {
    CertificationBodies,
    TheSpecificationsCollapse,
    SpecificationFields,
    ProductTypes
  },
  data () {
    return {
      items: [],
      listFilter: {
        product_name: '',
        product_type: null,
        owner_name: '',
        certificate_nr: '',
        certification_body_id: '',
        conformity_sign_id: '',
        specifications: {}
      }
    }
  },
  props: {
    withFilter: {
      type: Boolean,
      default: true
    },
    productTypeId: {
      type: Number
    }
  },
  mounted () {
    if (this.$route.query.restore === 'true') {
      this.listFilter = JSON.parse(sessionStorage.getItem('certificates_filter'))
      return
    }

    if (this.$route.query.product_type_id) {
      this.listFilter.product_type = this.productTypeId
      return
    }

    this.listFilter = { ...this.listFilter, ...this.searchFields }
  },
  methods: {
    /**
     * @param productTypeId
     *
     * @return {Promise}
     */
    async loadSpecifications (productTypeId) {
      if (productTypeId === null) return

      this.items = await this.$api.get('specifications', {
        params: { product_type_id: productTypeId }
      })
    },
    /**
     * @param value
     *
     * @return {void}
     */
    setProductType (value) {
      if (value === null) {
        this.items = []
      }

      this.listFilter.product_type = value

      if (this.$route.query.restore !== 'true') {
        // Reset specifications filter
        this.listFilter.specifications = {}
      }

      this.loadSpecifications(this.listFilter.product_type).then()
    },
    setCertificationBody (value) {
      this.listFilter.certification_body_id = value
    },
    setConformityMarkFilter (value) {
      this.listFilter.conformity_sign_id = value
    }
  },
  computed: {
    ...mapGetters('certificates/searchResults', ['searchFields'])
  },
  watch: {
    /**
     *
     * @param value
     */
    listFilter: {
      handler: function (value) {
        this.$emit('filter', value)
      },
      deep: true
    }
  }
}
</script>
