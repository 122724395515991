/**
 *
 * @param {*} required
 * @param {*} appendTo
 * @param {*} target
 */
export const appendCustomRequired = (required, appendTo, target) => {
  let isNotValid = false

  for (let tag of required) {
    if (typeof target[tag] !== 'undefined') continue

    const element = document.querySelector(`#${tag}`)

    if (!element || !element.classList.contains('multiselect__input')) continue

    let higherElement = element.parentElement
    let hasElement = higherElement.classList.contains(appendTo)

    while (hasElement === false) {
      higherElement = higherElement.parentElement
      hasElement = higherElement.classList.contains(appendTo)
    }

    if (higherElement.classList.contains(appendTo) === false) continue

    higherElement.classList.add('validation--error')

    isNotValid = true
  }

  return isNotValid
}

/**
 *
 * @param obj
 * @param rules  each rule is separated by a pipe. If the rule contains any value its shown by a :
 * sample rule: required|length:3
 */
export const applyRule = (obj, rules) => {
  // rules => 'required|length:3'
  const rule = rules.includes('|') ? rules.split('|') : [rules]
  const lengthDefault = 3

  rule.forEach(r => {
    const length = r.includes('length') ? r.split(':')[1] : lengthDefault

    for (let prop in obj) {
      if (!obj.hasOwnProperty(prop)) continue

      if (typeof obj[prop] === 'string' && obj[prop].length < length) {
        Reflect.deleteProperty(obj, prop)
      }
    }
  })
}
