import Api from '../services/api'
import { SET_USERS } from './mutationTypes'

const state = {
  /**
   * @type {{}}
   */
  users: {}
}

const getters = {
  /**
   * '
   * @param state
   *
   * @returns {Array|getters.users|(function(*))|*|modules.users|{namespaced, modules}}
   */
  users (state) {
    return state.users
  }
}

const mutations = {
  /**
   *
   * @param state
   * @param users
   *
   * @returns {void}
   */
  [SET_USERS] (state, users) {
    state.users = users
  }
}

const actions = {
  /**
   *
   * @param commit
   * @param options
   *
   * @returns {Promise<void>}
   */
  async fetch ({ commit } = {}, options = {}) {
    const api = new Api()
    const { limit, page, query, sortBy, orderBy } = options

    commit('setUsers', await api.get('users', { params: {
      limit,
      page,
      ...query,
      sortBy,
      orderBy
    } }))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
