<template>
  <div class="downloads">
    <h1 class=pageheading>{{ $t('downloads.title') }}</h1>
    <p v-html="$t('downloads.description')"></p>
    <div class="download-grid">
      <h5>{{ $t('downloads.links.logo.title') }}</h5>
      <ul>
        <li>
          <a href="/keymark-signs/Keymark-Cmjn.ai" download >Keymark-Cmjn.ai</a>
        </li>
        <li>
          <a href="/keymark-signs/Keymark-Cmjn.eps" download >Keymark-Cmjn.eps</a>
        </li>
        <li>
          <a href="/keymark-signs/Keymark-Cmjn.pdf" download >Keymark-Cmjn.pdf</a>
        </li>
        <li>
          <a href="/keymark-signs/Keymark-NB.ai" download >Keymark-NB.ai</a>
        </li>
        <li>
          <a href="/keymark-signs/Keymark-NB.eps" download >Keymark-NB.eps</a>
        </li>
        <li>
          <a href="/keymark-signs/Keymark-NB.pdf" download >Keymark-NB.pdf</a>
        </li>
        <li>
          <a href="/keymark-signs/Keymark-Pant.ai" download >Keymark-Pant.ai</a>
        </li>
        <li>
          <a href="/keymark-signs/Keymark-Pant.eps" download >Keymark-Pant.eps</a>
        </li>
        <li>
          <a href="/keymark-signs/Keymark-Pant.pdf" download >Keymark-Pant.pdf</a>
        </li>
      </ul>
      <h5>{{ $t('downloads.links.excelSheets.title') }}</h5>
      <div class="excel-sheet">
        <ul>
          <li>
            <a href="/keymark-signs/ID000_Certificates_YYYY-MM-DD.xlsx" download >
              {{ $t('downloads.links.excelSheets.import') }}
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TheDownloads'
}
</script>
