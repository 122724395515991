<template>
  <div :class="{ blank: isBlank }" id="app">
    <LoadingSpinner />
    <TheNavbar v-if="hasNav"/>
    <main>
      <b-container fluid>
        <b-row>
          <router-view/>
        </b-row>
      </b-container>
      <b-container fluid class="footer">
        <b-row>
          <b-col>
            <b-nav>
              <b-nav-item><a href="#">{{ $t('common.footer.imprint') }}</a></b-nav-item>
              <b-nav-item><a href="https://www.keymark.eu">{{ $t('common.footer.website') }}</a></b-nav-item>
              <b-nav-item v-if="token !== null">
                <Logout/>
              </b-nav-item>
              <b-nav-item v-if="token === null" :to="{ name: 'login' }">
                {{ $t('common.footer.login') }}
              </b-nav-item>
            </b-nav>
          </b-col>
        </b-row>
      </b-container>
    </main>
  </div>
</template>
<script>
import TheNavbar from './components/TheNavbar'
import LoadingSpinner from './components/LoadingSpinner'
import Logout from './components/Logout'
import Login from './services/login'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    Logout,
    TheNavbar,
    LoadingSpinner
  },
  data () {
    return {
      isAuthenticated: false,
      refreshId: null
    }
  },
  watch: {
    isAuthenticated (value) {
      if (!value && this.refreshId) {
        clearInterval(this.refreshId)
        return
      }

      this.refreshId = setInterval(async () => {
        const token = await Login.refresh()

        this.setToken(token)
      }, 1800000)
    },
    token (value) {
      this.isAuthenticated = !!value
      this.setVisibility(value ? 'private' : 'public')
    }
  },
  mounted () {
    this.setVisibility(this.token ? 'private' : 'public')
  },
  computed: {
    ...mapGetters('users/auth', ['token']),
    /**
     *
     * @return {boolean}
     */
    isBlank: function () {
      return (this.$route.path === '/login' || this.$route.path === '/reset')
    },
    hasNav () {
      const state = this.$route.query.nav

      return !(state && state === 'false')
    }
  },
  methods: {
    ...mapMutations('common/certificationBodies', ['setVisibility']),
    ...mapMutations('users/auth', ['setToken'])
  }
}
</script>

<style lang="scss">
  @import './assets/styles/main.scss';
</style>
