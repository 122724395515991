const search = {
  title: 'Keymark Zertifikats-Datenbank',
  navigation: {
    certificate_search: 'Keymark Zertifikat suchen',
    certification_body_search: 'Zertifizierungsstelle suchen'
  },
  form: {
    certificate_search: {
      heading: {
        product: 'Produkt',
        certificate: 'Zertifikat',
        holder: 'Zertifikats-Inhaber'
      },
      label: {
        product_name: 'Produkt-Name',
        product_category: 'Produkt-Kategorie',
        certificate_number: 'Zertifikats-Nummer',
        standard_reference: 'Standard Referenz',
        body_name: 'Zertifizierungsstelle',
        manufacturer: 'Hersteller',
        manafacturer_country: 'Herkunfstland Hersteller'
      },
      placeholder: {
        product_name: 'Produktnamen eingeben',
        product_category: 'Wähle eine Produktkategorie',
        certificate_number: 'Zertifikatsnummer eintragen',
        standard_reference: 'Wähle eine Referenz',
        body_name: 'Zertifizierungsstelle auswählen',
        manufacturer: 'Hersteller auswählen',
        manufacturer_country: 'Land auswählen'
      }
    }
  }
}

export default search
