import Api from '../services/api'
import { SET_CERTIFICATION_BODIES, SET_FILTERS, RESET_FILTER, SET_VISIBILITY } from './mutationTypes'

const state = {
  /**
   * @type {{}}
   */
  certificationBodies: {},
  visibility: 'public',
  filter: sessionStorage.getItem('certification_body_filter')
}

const mutations = {
  /**
   *
   * @param state
   * @param payload
   */
  [SET_CERTIFICATION_BODIES] (state, payload) {
    state.certificationBodies = payload
  },

  /**
   *
   * @param state
   * @param payload
   */
  [SET_FILTERS] (state, payload) {
    sessionStorage.setItem('certification_body_filter', JSON.stringify(payload))

    state.filter = sessionStorage.getItem('certification_body_filter')
  },

  /**
   *
   * @param state
   * @param payload
   */
  [RESET_FILTER] (state, payload = {}) {
    sessionStorage.setItem('certification_body_filter', JSON.stringify(payload))

    state.filter = payload
  },

  /**
   *
   * @param state
   * @param visibility
   */
  [SET_VISIBILITY] (state, visibility) {
    state.visibility = visibility
  }
}

const getters = {
  /**
   *
   * @param state
   * @returns {getters.certificationBodies|(function(*))|Array|*|{mutations, state, getters, actions, namespaced}}
   */
  certificationBodies (state) {
    return state.certificationBodies
  },
  /**
   *
   * @param state
   * @returns {*}
   */
  searchFilter (state) {
    if (typeof state.filter !== 'string') {
      return {}
    }

    return JSON.parse(state.filter)
  },

  /**
   *
   * @param state
   * @return {getters.visibility|(function(*))|string|*|string}
   */
  visibility (state) {
    return state.visibility
  }
}

const actions = {
  /**
   *
   * @param commit
   * @param options
   * @returns {Promise<void>}
   */
  async fetch ({ commit } = {}, options = {}) {
    const api = new Api()
    let {
      id,
      include,
      query,
      page,
      limit,
      sortBy,
      orderBy
    } = options

    commit('common/loadingSpinner/START_LOADING', null, { root: true })
    commit('setCertificationBodies', await api.get(`certificationBodies`, {
      id,
      params: {
        include,
        ...query,
        page,
        limit,
        sortBy,
        orderBy
      }
    }))
    commit('common/loadingSpinner/END_LOADING', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}
