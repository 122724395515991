<template>
  <div
    class="conformity_signs"
    v-if=" 'data' in signs">
    <div
      v-for="(sign, key) in signs.data"
      :key="key">
     <div v-if="withAdditionalSigns(sign)">
       <b-form-radio
         v-model="state"
         :value="sign.id"
         name="signs">
         {{ $t(`conformitySigns.${sign.sign}`)}}
       </b-form-radio>
       <img :src="sign.sign_url" alt=""/>
     </div>
      <div v-if="sign.sign === 'keymark_only'">
        <b-form-radio
          v-model="state"
          :value="sign.id"
          name="signs">
          {{ $t(`conformitySigns.${sign.sign}`)}}
        </b-form-radio>
        <img :src="sign.sign_url" alt=""/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ConformitySigns',
  data () {
    return {
      state: 1,
      type: ''
    }
  },
  props: {
    value: {
      type: Number
    },
    productTypeId: {
      type: Number
    }
  },
  mounted () {
    this.fetch()
  },
  computed: {
    ...mapGetters('certificates/conformitySigns', ['signs']),
    ...mapGetters('common/productTypes', ['types'])
  },
  methods: {
    ...mapActions('certificates/conformitySigns', ['fetch']),
    withAdditionalSigns (sign) {
      const isSign = sign.sign === 'vdi_only' || sign.sign === 'keymark_with_vdi'

      return isSign && this.isType(this.type)
    },
    isType (type) {
      return type === 'Thermal insulation products for building equipment and industrial installations'
    }
  },
  watch: {
    value (val) {
      this.state = val
    },
    state (val) {
      this.$emit('input', val)
    },
    /**
     *
     * @param id
     */
    productTypeId (id) {
      if (!id) {
        return
      }

      const { data } = this.types
      const type = data.filter(({ id }) => this.productTypeId === id)

      if (type) {
        this.type = data.filter(({ id }) => this.productTypeId === id)[0]['type']

        if (!this.isType(this.type)) {
          this.state = 2
        }
      }
    }
  }
}
</script>
