const certificationBody = {
  titles: {
    update: 'Update Empowered Certification Body',
    create: 'Create Empowered Certification Body',
    title: 'Search Results for Empowered Certification Bodies'
  },
  labelAsTitle: {
    name: 'Name',
    registrationNumber: 'Identification Code (ID)',
    country: 'Country',
    userEmail: 'Email address of the associated user'
  },
  table: {
    country: 'Country',
    productType: 'Scope of Empowerment'
  },
  list: {
    label: {
      name: 'Empowered Certification Body',
      registrationNumber: 'Identification Code (ID)',
      country: 'Select country',
      productType: 'Select product type'
    },
    placeholder: {
      name: 'Enter the name of the certification body',
      registrationNumber: 'Enter Identification Code',
      country: 'Enter the certification body country',
      productType: 'Select a product type'
    }
  },
  modal: {
    title: 'Certification body deletion',
    text: 'Are you sure you want to delete this certification body?'
  }
}

export default certificationBody
