export default {
  methods: {
    /**
     *
     * @param formObject
     *
     * @returns {void}
     */
    reset (formObject) {
      for (let props in formObject) {
        if (formObject.hasOwnProperty(props)) {
          formObject[props] = null
        }
      }
    },
    /**
     *
     * @param formObject
     * @returns {boolean}
     */
    isValid (formObject) {
      let countFilled = 0

      for (let props in formObject) {
        if (!formObject.hasOwnProperty(props)) continue

        if (typeof formObject[props] === 'undefined') continue

        if (typeof formObject[props] === 'number') countFilled++

        if (formObject[props] !== null && formObject[props].length > 0) {
          countFilled++
        }
      }

      return countFilled > 0
    }
  }
}
