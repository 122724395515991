const navbar = {
  items: {
    profile: 'Profile settings',
    faq: 'FAQ',
    help: 'Help',
    logout: 'Logout',
    owners: 'Companies',
    certificationBodies: 'Certification bodies',
    myCertificates: 'Our certificates',
    laboratory: 'Testing Laboratories',
    home: 'Home',
    productType: 'PRODUCTS',
    standards: 'Standards',
    users: 'Users',
    downloads: 'Downloads'
  }
}

export default navbar
