<template>
  <multiselect
    v-if="ownersLoaded === true"
    :placeholder="$t('common.selects.owner')"
    track-by="name"
    label="name"
    id="owner_id"
    :options="owners.data"
    v-model="selectedOwner">
  </multiselect>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'Owners',
  props: {
    preselect: {
      type: Number
    },
    showLabel: {
      type: Boolean,
      default: true
    }
  },
  mounted () {
    this
      .fetch({ limit: 1000000 })
      .then(() => {
        if (this.owners.hasOwnProperty('data') === false) return

        const owner = this.owners.data.filter(({ id }) => id === this.preselect) || null

        this.selectedOwner = owner ? owner[0] : null
      })
  },
  data () {
    return {
      selectedOwner: null
    }
  },
  components: { Multiselect },
  computed: {
    ...mapGetters('certificates/owners', ['owners']),
    /**
     * @return {Boolean}
     */
    ownersLoaded () {
      return this.owners.hasOwnProperty('data')
    }
  },
  watch: {
    /**
     * @param value
     */
    selectedOwner (value) {
      if (value === null || typeof value === 'undefined') {
        this.$emit('owner', null)

        return
      }

      this.$emit('owner', value.id)
    },
    /**
     * @param value
     */
    preselect (value) {
      if (this.owners.hasOwnProperty('data') === false) return

      const owner = this.owners.data.filter(({ id }) => id === value) || null

      this.selectedOwner = owner ? owner[0] : null
    },
    /**
     * @param value
     */
    owners (value) {
      if (!this.preselect) return

      const owner = value.data.filter(({ id }) => id === value) || null

      this.selectedOwner = owner ? owner[0] : null
    }
  },
  methods: {
    ...mapActions('certificates/owners', ['fetch'])
  }
}
</script>
