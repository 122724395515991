<template>
  <div class="bubble">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'BubbleCount',
  props: {
    placeholder: {
      type: String
    }
  }
}
</script>
