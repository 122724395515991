const downloads = {
  title: 'Downloads for CBs',
  description: 'Here you will find different fomats of the KEYMARK logo for our certiicate holders and for your' +
    'use as empowered Certification Body. Furthermore we added the current valid version of other relevant' +
    'documents for you, which are not published on <a href="https://keymark.eu/" target="_blank">www.keymark.eu.</a>',
  links: {
    logo: {
      title: 'Logo'
    },
    excelSheets: {
      title: 'Excel sheets',
      import: 'Certificate Import Template'
    }
  }
}

export default downloads
