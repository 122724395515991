import Api from '../services/api'
import { SET_ME, RESET_ME } from './mutationTypes'

const state = {
  me: sessionStorage.getItem('user')
}

const getters = {
  /**
   *
   * @param state
   * @returns {Object}
   */
  me (state) {
    if (!state.me) {
      return {
        id: null,
        name: null,
        email: null
      }
    }

    return JSON.parse(state.me)
  },
  /**
   *
   * @param state
   * @return {boolean}
   */
  isPowerUser (state) {
    if (!state.me) {
      return false
    }

    return JSON.parse(state.me).rights === 'Power_user'
  },
  /**
   *
   * @param state
   * @return {boolean}
   */
  isAdmin (state) {
    if (!state.me) {
      return false
    }

    return JSON.parse(state.me).rights === 'Admin'
  }
}

const mutations = {
  /**
   *
   * @param state
   * @param payload
   */
  [SET_ME] (state, { data }) {
    sessionStorage.setItem('user', JSON.stringify(data))

    state.me = sessionStorage.getItem('user')
  },
  [RESET_ME] (state) {
    sessionStorage.setItem('user', JSON.stringify({
      id: null,
      name: null,
      email: null
    }))

    state.me = sessionStorage.getItem('user')
  }
}

const actions = {
  /**
   *
   * @param commit
   *
   * @return {Promise<void>}
   */
  async fetch ({ commit } = {}) {
    const api = new Api()

    commit('common/loadingSpinner/START_LOADING', null, { root: true })
    commit('setMe', await api.get('auth/me'))
    commit('common/loadingSpinner/END_LOADING', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
