import Api from '../services/api'
import { SET_SPECIFICATIONS } from './mutationTypes'

const state = {
  specifications: []
}

const getters = {
  /**
   *
   * @param state
   *
   * @return {*}
   */
  specifications (state) {
    return state.specifications
  }
}

const mutations = {
  /**
   *
   * @param state
   * @param specifications
   *
   * @constructor
   */
  [SET_SPECIFICATIONS] (state, specifications) {
    state.specifications = specifications
  }
}

const actions = {
  /**
   *
   * @param commit
   * @param productTypeId
   *
   * @return {Promise<void>}
   */
  async fetch ({ commit } = {}, productTypeId) {
    const api = new Api()
    const params = { product_type_id: productTypeId }

    commit('setSpecifications', await api.get('specifications', { params }))
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
