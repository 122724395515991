<template>
  <b-container fluid class="the-detail">
    <b-row align-h="between">
      <b-col
        cols="auto"
        v-if="!isEditMode">
        <b-button
          variant="outline-primary"
          @click="enterEditMode">
          {{ $t('common.edit') }}
        </b-button>
      </b-col>
      <b-col
        cols="auto"
        v-if="!isEditMode && isPowerUser">
        <b-button
          variant="success"
          @click="$router.push('/profile/new')">
          {{ $t('common.new') }}
        </b-button>
      </b-col>
      <b-col
        cols="auto"
        v-if="isEditMode">
        <b-button
          variant="danger"
          @click="leaveEditMode">
          {{ $t('common.cancel') }}
        </b-button>
      </b-col>
      <b-col
        cols="auto"
        v-if="isEditMode">
        <b-button
          variant="success"
          @click="update">
          {{ $t('common.update') }}
        </b-button>
      </b-col>
    </b-row>
    <div
      v-if="isEditMode"
      class="search">
      <b-form>
        <fieldset class="detailsection detailsection--productcategory">
          <legend class="detailsection__heading">{{ $t('profile.form.email') }}</legend>
          <b-form-input
            type="email"
            v-model="user.email">
          </b-form-input>
          <legend class="detailsection__heading">{{ $t('profile.form.name') }}</legend>
          <b-form-input v-model="user.name"></b-form-input>
          <legend class="detailsection__heading">{{ $t('profile.form.password') }}</legend>
          <b-form-input
            type="password"
            v-model="user.password">
          </b-form-input>
        </fieldset>
      </b-form>
    </div>
    <div
      v-if="!isEditMode"
      class="search">
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('profile.form.email') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input disabled :placeholder="user.email"></b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('profile.form.name') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input disabled :placeholder="user.name"></b-form-input>
          </b-col>
        </b-row>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { toaster } from '../services/toast'

export default {
  name: 'TheUserProfile',
  data () {
    return {
      editable: false,
      user: {}
    }
  },
  mounted () {
    let { id } = this.$route.params

    this.$api.get('users', { id }).then(({ data }) => {
      this.user = data
    })
  },
  computed: {
    ...mapGetters('users/me', ['isPowerUser']),
    /**
     *
     * @return {boolean}
     */
    isEditMode () {
      return this.editable
    }
  },
  methods: {
    ...mapActions('users/users', ['fetch']),
    /**
     *
     * @return {Promise<void>}
     */
    async update () {
      const initToaster = toaster(this['_bv__toast'])

      try {
        await this.$api.update('users', this.user.id, Object.deleteEmpty({
          email: this.user.email,
          name: this.user.name,
          password: this.user.password
        }))

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))
      } catch ({ data }) {
        const toaster = initToaster(data.message)

        toaster(this.$t('notifications.error.title'), this.$t(`notifications.error.${data.status_code}`))
      }

      this.editable = false
    },
    /**
     *
     * @return {void}
     */
    leaveEditMode () {
      this.editable = false
    },
    /**
     *
     * @return {void}
     */
    enterEditMode () {
      this.editable = true
    }
  }
}
</script>
