<template>
  <b-container
    class="the-detail"
    v-if="hasDetails">
    <b-row class="detailsection detailsection--head">
      <span class="detailsection__heading">{{ $t('detail.headings.registration_number') }}</span>
      <b-row class="detailsection__content">
        <b-col>
          <h1>{{ certificationBodies.data.registration_number }}</h1>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="detailsection detailsection--manufacturer">
      <h3 class="detailsection__heading">{{ $t('detail.headings.certificationBody') }}</h3>
      <b-row class="detailsection__content">
        <b-col col cols="12" md="12">
          <b-row align-v="start">
            <b-col
              cols="12"
              md="4"
              class="manufacturerinfo__address">
              <strong>{{ certificationBodies.data.name }}</strong> <br />
              <span v-if="certificationBodies.data.street !== null">
                {{ certificationBodies.data.street }} <br />
              </span>
              <span v-if="certificationBodies.data.zip !== null || certificationBodies.data.location !== null">
                {{ certificationBodies.data.zip }} {{ certificationBodies.data.location }}  <br />
              </span>
              <span v-if="certificationBodies.data.country_id !== null">
                {{ getCountryNameById }}
              </span>
            </b-col>
            <b-col cols="12" md="3" offset-md="2" class="manufacturerinfo__contact">
              <dl>
                <div v-if="certificationBodies.data.phone !== null">
                  <dt>{{ $t('common.phone') }}</dt>
                  <dd>{{ certificationBodies.data.phone }}</dd>
                </div>
                <div v-if="certificationBodies.data.fax !== null">
                  <dt>{{ $t('common.fax') }}</dt>
                  <dd>{{ certificationBodies.data.fax }}</dd>
                </div>
                <div v-if="certificationBodies.data.email !== null">
                  <dt>{{ $t('common.mail') }}</dt>
                  <dd>
                    <a :href="`mailto:${certificationBodies.data.email}`">
                      {{ certificationBodies.data.email }}
                    </a>
                  </dd>
                </div>
                <div v-if="certificationBodies.data.website !== null">
                  <dt>{{ $t('common.web') }}</dt>
                  <dd>
                    <a
                      target="_blank"
                      :href="certificationBodies.data.website">
                      {{ certificationBodies.data.website }}
                    </a>
                  </dd>
                </div>
              </dl>
            </b-col>
            <b-col
              align-self="start"
              cols="12"
              md="3"
              v-if="typeof certificationBodies.data.logo !== 'undefined' || typeof certificationBodies.data.logo_url !== 'undefined'">
              <img
                class="logo"
                :src="logoURL"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="detailsection detailsection--productcategory">
      <h3 class="detailsection__heading">{{ $t('owner.labelAsTitle.assignments') }}</h3>
      <b-row class="detailsection__content">
        <b-col
          cols="12"
          v-for="(assignment, index) in this.uniqueAssignments"
          :key="index">
          <span>
            {{ mapProductTypesName(assignment) }}
          </span>
        </b-col>
      </b-row>
    </b-row>
    <b-row class="the-results__toolbar">
      <b-container fluid class="toolbar">
        <b-row align-h="between">
          <b-col>
            <b-button
              @click="goBack"
              variant="outline-primary">
              {{ $t('owner.toolbar.to_results') }}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-row>
  </b-container>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'TheCertificationBodiesDetail',
  data () {
    return {
      from: ''
    }
  },
  mounted () {
    this.fetchCountries({ limit: 500 })
  },
  methods: {
    ...mapActions('common/certificationBodies', ['fetch']),
    ...mapActions('common/countries', { fetchCountries: 'fetch' }),
    /**
     * @param id
     *
     * @return {string|void}
     */
    mapProductTypesName (id) {
      if (!('data' in this.certificationBodies) || !id) return

      const type = this.certificationBodies.data.productTypes.data.filter(item => item.id === id) || null

      return type ? type[0].type : ''
    },
    goBack () {
      this.$router.push(`${this.from}?restore=true`)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(async vm => {
      const { id } = to.params

      vm.from = from.path
      await vm.fetch({ id, include: 'productTypes,laboratories' })
    })
  },
  computed: {
    ...mapGetters('common/countries', ['countries']),
    ...mapGetters('common/certificationBodies', ['certificationBodies']),
    /**
     * @return {array}
     */
    uniqueAssignments () {
      if (!('data' in this.certificationBodies) && !('assignments' in this.certificationBodies.data)) return []

      let types = []

      for (let assignment of this.certificationBodies.data.assignments) {
        if (types.includes(assignment.product_type_id)) continue

        types.push(assignment.product_type_id)
      }

      return types
    },
    /**
     *
     * @return {*}
     */
    logoURL () {
      return this.certificationBodies.data.logo
        ? this.certificationBodies.data.logo
        : this.certificationBodies.data.logo_url
          ? this.certificationBodies.data.logo_url
          : null
    },
    /**
     * @return {*}
     */
    getCountryNameById () {
      if (!('country_id' in this.certificationBodies.data) && !this.countries.length) return ''

      const countries = this.countries.filter(country => country.id === this.certificationBodies.data.country_id)

      if (!countries.length) return ''

      return countries[0].country
    },
    /**
     *
     * @return {boolean}
     */
    hasDetails () {
      return Object.keys(this.certificationBodies).length > 0
    }
  }
}
</script>
