<template>
  <b-navbar
    sticky
    toggleable="lg"
    type="dark"
    variant="primary">
    <b-navbar-brand to="/">{{ $t('navbar.items.home') }}</b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="isAdmin || isPowerUser">
        <b-nav-item :to="{ path: '/owners/1/list'}">{{ $t('navbar.items.owners') }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="token">
        <b-nav-item :to="{ path: `/me/certificates/1`, params: { page: 1 } }">{{ $t('navbar.items.myCertificates') }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="isAdmin || isPowerUser">
        <b-nav-item @click="pushToCertificationBodies">{{ $t('navbar.items.certificationBodies') }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="isAdmin || isPowerUser">
        <b-nav-item :to="{ path: '/laboratories/1/list'}">{{ $t('navbar.items.laboratory') }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="isPowerUser">
        <b-nav-item :to="{ path: '/productTypes/1/list'}">{{ $t('navbar.items.productType') }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="isPowerUser">
        <b-nav-item :to="{ path: '/standards/1/list'}">{{ $t('navbar.items.standards') }}</b-nav-item>
      </b-navbar-nav>
      <b-navbar-nav v-if="isPowerUser">
        <b-nav-item :to="{ path: '/users/1/list'}">{{ $t('navbar.items.users') }}</b-nav-item>
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item v-if="!token" :to="{ name: 'login' }">
          Login
        </b-nav-item>
        <b-nav-item-dropdown
          v-if="token"
          :text="me.name">
          <b-dropdown-item :to="{ path: `/users/${me.id}`}">{{ $t('navbar.items.profile') }}</b-dropdown-item>
          <b-dropdown-item to="/downloads">{{ $t('navbar.items.downloads') }}</b-dropdown-item>
          <b-dropdown-item @click.native="logout">
            {{ $t('navbar.items.logout') }}
          </b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'TheNavbar',
  computed: {
    ...mapGetters('users/auth', ['token']),
    ...mapGetters('users/me', ['me', 'isAdmin', 'isPowerUser'])
  },
  methods: {
    ...mapMutations('users/me', ['resetMe']),
    ...mapMutations('users/auth', ['setToken']),
    /**
     *
     * @return {Promise<void>}
     */
    async logout () {
      this.resetMe()
      this.setToken(null)

      this.$router.push('/login')
    },
    /**
     * @return {void}
     */
    pushToCertificationBodies () {
      this.$router.push({ path: '/certificationBodies/1/list' })
    }
  }
}
</script>
