const state = {
  searchValues: {}
}
const getters = {
  searchValues (state) {
    return state.searchValues
  }
}
const mutations = {
  SET_SEARCH_VALUES (state, values) {
    state.searchValues = values
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
