/**
 * Main entry file for the english translation.
 *
 * Please separate component-based translation into a single file;Which can then be imported and stored into the object.
 */
import search from './views/search'
import results from './views/results'
import detail from './views/detail'
import common from './common'
import login from './views/login'
import notifications from './notifications'
import myCertificates from './views/myCertificates'
import owner from './views/owner'
import certificationBody from './views/certificationBody'
import navbar from './views/navbar'
import profile from './views/profile'
import certificateImport from './views/certificateImport'
import modals from '../modals'
import laboratories from './views/laboratories'
import standards from './views/standards'
import productTypes from './views/productTypes'
import conformitySigns from './conformitySigns'
import reset from './views/reset'
import downloads from './views/downloads'

const en = {
  search,
  results,
  detail,
  common,
  login,
  notifications,
  myCertificates,
  owner,
  certificationBody,
  navbar,
  profile,
  modals,
  certificateImport,
  laboratories,
  standards,
  productTypes,
  conformitySigns,
  reset,
  downloads
}

export default en
