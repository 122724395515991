import Api from '../services/api'
import { SET_DETAILS } from './mutationTypes'

const state = {
  /**
   * @type Array
   */
  certificateDetails: []
}

const getters = {
  /**
   *
   * @param state
   * @returns {Array|*|modules.certificates|{namespaced, modules}|RTCCertificate[]}
   */
  details (state) {
    return state.certificateDetails
  }
}

const mutations = {
  /**
   *
   * @param state
   * @param details
   *
   * @returns void
   */
  [SET_DETAILS] (state, details) {
    state.certificateDetails = details
  }
}

const actions = {
  /**
   *
   * @param commit
   * @param options  {Object}
   * @param options.include  {String}  include option: 'owner,productType' => no space between commas
   * @param options.id  {Number}  id option: 10 => id of whatever entity to get
   * @param options.limit  {Number}  limit option: 100 => limit results per page
   * @param options.page  {String}  page option: ?page=2 => next page of a pagination
   * @returns {Promise<void>}
   */
  async fetch ({ commit } = {}, options = {}) {
    const api = new Api()
    let { id, include } = options

    commit('common/loadingSpinner/START_LOADING', null, { root: true })
    commit('setCertificateDetails', await api.get(`certificates`, { id, params: { include } }))
    commit('common/loadingSpinner/END_LOADING', null, { root: true })
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
