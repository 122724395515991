<template>
  <b-container class="the-detail">
    <b-form @submit="create">
      <b-row>
        <h1 class="pageheading">{{ $t('profile.title') }}</h1>
      </b-row>
      <b-row class="the-detail__toolbar">
        <CRUDToolbar
          :hasBackButton="false"
          :clickFn="create">
        </CRUDToolbar>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('profile.form.name') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
            id="name"
            v-model="formInputs.name"
            required>
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('profile.form.email') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
            id="email"
            type="email"
            v-model="formInputs.email"
            required>
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('profile.form.password') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <b-form-input
            type="password"
            id="password"
            v-model="formInputs.password"
            required>
            </b-form-input>
          </b-col>
        </b-row>
      </b-row>
      <b-row class="detailsection detailsection--productcategory">
        <h3 class="detailsection__heading">{{ $t('profile.form.rights') }}</h3>
        <b-row class="detailsection__content">
          <b-col>
            <multiselect
              :placeholder="$t('profile.form.rights')"
              id="rights"
              :options="options"
              v-model="formInputs.rights">
            </multiselect>
          </b-col>
        </b-row>
      </b-row>
      <CRUDToolbar :clickFn="create"></CRUDToolbar>
    </b-form>
  </b-container>
</template>

<script>
import CRUDToolbar from '../components/CRUDToolbar'
import { toaster } from '../services/toast'
import Multiselect from 'vue-multiselect'
import { appendCustomRequired } from '../services/request'

export default {
  name: 'TheUserProfileCreate',
  data () {
    return {
      formInputs: {
        name: '',
        rights: null,
        email: '',
        password: ''
      },
      options: ['Admin', 'Member']
    }
  },
  components: { CRUDToolbar, Multiselect },
  methods: {
    /**
     *
     * @return {Promise<void>}
     */
    async create () {
      const initToaster = toaster(this['_bv__toast'])

      if (appendCustomRequired(['rights'], 'multiselect', this.formInputs)) {
        return
      }

      try {
        await this.$api.create('users', {
          ...this.formInputs
        })

        initToaster(200)(this.$t('notifications.success.title'), this.$t(`notifications.success.${200}`))

        this.$router.push(`/users/1/list`)
      } catch ({ data }) {
        const toaster = initToaster(data.message)

        toaster(this.$t('notifications.error.title'), this.$t(`notifications.error.${data.status_code}`))
      }
    }
  }
}
</script>
