const myCertificates = {
  titles: {
    title: 'Search results for your certificates'
  },
  modal: {
    title: 'Certificate deletion',
    text: 'Are you sure you want to continue?',
    buttons: {
      no: 'No',
      yes: 'Yes'
    }
  },
  toolbar: {
    new: 'New',
    delete: 'Delete',
    export: 'PDF Export Selected',
    exportCsv: 'Export selected as CSV',
    exportAll: 'Export all as CSV',
    import: 'Import certificates'
  }
}

export default myCertificates
