// Critical imports
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Api from './services/api'

// Bootstrap vue support
import BootstrapVue from 'bootstrap-vue'

// Locals translation imports
import VueI18n from 'vue-i18n'
import locales from '../locales/locales'

import VeeValidate from 'vee-validate'

// Global filter
import { humanDate } from './services/date'

require('./services/prototyping')

// Plugin init
Vue.use(VeeValidate, {
  inject: true,
  fieldsBagName: 'veeFields',
  errorBagName: 'veeErrors'
})
Vue.use(BootstrapVue)
Vue.use(VueI18n)

// Init and select locale
const i18n = new VueI18n({
  locale: 'en',
  messages: locales
})

Vue.config.productionTip = false

Vue.filter('humanDate', humanDate)
Vue.filter('nl2br', function (value) {
  if (!value.includes('\n')) {
    return value
  }

  return value.replace(/\n/g, '<br>')
})

Vue.prototype.$api = new Api()
Vue.prototype.$idToken = 'id_token'

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
