const results = {
  title: 'Search Results For KEYMARK Certificates',
  list: {
    product_name: 'Product Name',
    product_category: 'Product Type',
    certificate_holder: 'Certificate Holder',
    registration_number: 'Registration Number',
    expiration_date: 'Valid Until',
    certification_body: 'Certification Body'
  },
  toolbar: {
    new_search: 'New Search'
  },
  inputs: {
    certificate_holder: 'Enter certificate holder'
  }
}

export default results
