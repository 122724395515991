const laboratories = {
  titles: {
    title: 'Search Results for Testing Laboratory',
    create: 'Create Testing Laboratory',
    update: 'Update Testing Laboratory'
  },
  labels: {
    name: 'Name of the Testing Laboratory.'
  }
}

export default laboratories
