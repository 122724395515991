<template>
  <div
    :class="[this.variant === 'warning' ? 'logger--warning' : 'logger--error']"
    class="logger">
    <h3>{{ title }}</h3>
    <div
      :key="key"
      v-for="(log, key) in logs">
      {{ key + 1 }}) {{ log }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ErrorLog',
  props: {
    variant: {
      type: String
    },
    logs: {
      type: Array
    }
  },
  computed: {
    title () {
      return this.variant === 'warning'
        ? this.$t('notifications.logger.warning')
        : this.$t('notifications.logger.error')
    }
  }
}
</script>
