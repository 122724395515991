const owner = {
  titles: {
    update: 'Update Certificate Holder',
    create: 'Create Certificate Holder',
    title: 'Search Results for Certificate Holder'
  },
  label: {
    name: 'Enter Certificate Holder',
    street: 'Enter the street',
    zip: 'Enter a zip code',
    location: 'Enter a location',
    country: 'Enter a country',
    email: 'Enter an email address',
    website: 'Enter a website',
    phone: 'Enter a phone number',
    fax: 'Enter a fax number',
    moreContacts: 'Enter additional information about your contact details'
  },
  labelAsTitle: {
    name: 'Name',
    street: 'Street',
    zip: 'Zip code',
    location: 'Location',
    country: 'Country',
    email: 'Email',
    website: 'Website',
    phone: 'Phone',
    fax: 'Fax',
    moreContacts: 'Additional contact details',
    logo: 'Logo upload',
    intern_field: 'Internal note',
    numberOfProductionSites: 'Number of production sites',
    assignments: 'Scope of Empowerment'
  },
  list: {
    label: {
      name: 'Name of the owner',
      country: 'Owners country'
    },
    placeholder: {
      name: 'Name of the owner',
      country: 'Country'
    }
  },
  modal: {
    title: 'Owner deletion',
    text: 'Are you sure you want to delete this owner?'
  },
  toolbar: {
    to_results: 'Back to Resultlist'
  }
}

export default owner
