const productTypes = {
  titles: {
    create: 'Create product types',
    update: 'Update product types',
    list: 'Search results for product types'
  },
  productTypes: 'Product types',
  labels: {
    productType: 'Product type'
  }
}

export default productTypes
