/**
 *
 * @param toast
 * @returns {function(*=): Function}
 */
const toaster = toast => {
  return code => {
    let variant

    if (isOfTypeSuccess(parseInt(code))) variant = 'success'

    if (isOfTypeError(parseInt(code))) variant = 'danger'

    return (title, text) => {
      toast.toast(text, { variant, title, toaster: 'b-toaster-bottom-center' })
    }
  }
}

/**
 *
 * @param code
 * @returns {boolean}
 */
const isOfTypeSuccess = code => {
  return [200, 201, 202].includes(code)
}

/**
 *
 * @param code
 * @returns {boolean}
 */
const isOfTypeError = code => {
  return [422, 500, 400, 401, 403, 404].includes(code)
}

export { toaster }
