const state = {
  isLoading: false
}

const getters = {
  isLoading (state) {
    return state.isLoading
  }
}

const mutations = {
  START_LOADING (state) {
    state.isLoading = true
  },

  END_LOADING (state) {
    state.isLoading = false
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations
}
