<template>
    <b-container class="the-search">
        <b-row>
            <h1 class="pageheading">{{ $t("search.title") }}</h1>
        </b-row>
        <b-row class="the-search__tabs">
            <b-tabs>
                <b-tab :title='$t("search.navigation.certificate_search")' :active="currentTab === 'certificates'">
                    <TheCertificateSearch />
                </b-tab>
                <b-tab :title='$t("search.navigation.certification_body_search")' :active="currentTab === 'certificationBodies'">
                    <TheCertificationBodySearch />
                </b-tab>
            </b-tabs>
        </b-row>
    </b-container>
</template>

<script>
import TheCertificateSearch from '../components/TheCertificateSearch.vue'
import TheCertificationBodySearch from '../components/TheCertificationBodiesSearch.vue'

export default {
  name: 'TheSearch',
  data () {
    return {
      currentTab: 'certificates'
    }
  },
  components: {
    TheCertificateSearch,
    TheCertificationBodySearch
  },
  beforeRouteEnter (to, from, next) {
    next(vue => {
      vue.currentTab = from.path.includes('certificationBodies') ? 'certificationBodies' : 'certificates'
    })
  }
}
</script>
